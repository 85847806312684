import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useAppContext } from '../../../context';


const TYPES_ROUES = [
  {
    label: "DEUX ROUES",
    value: 2,
  },
  {
    label: "QUATRE ROUES",
    value: 4,
  },
]

function TypedeRoueSelector({ roue, onChange, likeObject = true, ...other }) {

  const [options, setOptions] = useState([])

  let value = TYPES_ROUES.find(item => item.value === parseInt(roue))
  const { isAuth, setIsAuth } = useAppContext();

  useEffect(() => {
    const rouesOptions = [];

    if (isAuth) {
      if( isAuth?.userRole === "client"){
        if (!isAuth["vt"] && !isAuth["2_roues"]) {
        //roues non définies donc définir toutes les opérations
        setOptions(TYPES_ROUES)
      } else {
        if (isAuth["2_roues"]) {
          rouesOptions.push(TYPES_ROUES[0])
        }

        if (isAuth["vt"]) {
          rouesOptions.push(TYPES_ROUES[1])
        }
        if (rouesOptions.length === 1) {
          onChange(rouesOptions?.[0]?.value)
        }
        setOptions(rouesOptions)

      }
      }else{
        setOptions(TYPES_ROUES)
      }
      
    }

    console.log("salut la team", rouesOptions,roue)
  }, [roue])

  return (
    <div className="form-group">
      <label htmlFor="field6">{"Type de prestation".toUpperCase()}</label>
      <Select
        {...other}
        className={"text-base"}
        value={value}
        options={options}
        placeholder={''}
        onChange={(v) => {
          if (likeObject) {
            onChange(v);
          } else {
            onChange(v?.value);
          }

        }}
      />
    </div>
  );
}

export default TypedeRoueSelector;