import React, { useCallback, useEffect, useState } from "react";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import { useLocation } from "react-router-dom";
import axios from "axios";
import CitiesSelector from "../Utils/Selectors/CitiesSelector";
import ZipCodesSelector from "../Utils/Selectors/ZipCodesSelector";
import { ContactFormInput } from "../contact/CarnetAddress";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";
import { urlSplit } from "../../utils/Functions/otherFunctions";
import useCountries from "../../states/countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import { InputNomSearch } from "../contact/InputNomSearch";
import CivilitySelector from "../Utils/Selectors/CivilitySelector";
import InputComponent from "../InputComponent/InputComponent";
import PhoneInputComponent from "../phoneInputComonent/PhoneInputComonent";
import { ButtonComponent } from "../ButtonComponent";
import { ExpeditionStore } from "../../store/expedition";
import { useAppContext } from "../../context";
import { ErrorStore } from "../../store/inputError";
import { VerifyUserStep } from "../../utils/Functions/verifyDropdown";
import { toast } from "react-toastify";

export const ShippingUser = ({
  toggleAccordion,
  contactType,
  shippingProcess,
}) => {
  const { isAuth, setIsAuth } = useAppContext();
  const { role } = urlSplit();
  const [changeCountry, setChangeCountry] = useState(false);

  const user = ExpeditionStore((state) => state.user);
  const resetUserData = ExpeditionStore((state) => state.resetUserData);
  const updateUserDat = ExpeditionStore((state) => state.updateUserData);
  const setUserData = ExpeditionStore((state) => state.setUserData);
  const errorInformation = ErrorStore((state) => state.errorField);
  const setMessageError = ErrorStore((state) => state.setMessageError);
  const resetErrorField = ErrorStore((state) => state.resetErrorField);
  const nextStepper = ExpeditionStore((state) => state.nextStepper);
  const UserStateData = user[contactType];

  const { countries: ListCountries, setCountries: setListCountries } =
    useCountries();

  // postalCode villep paysp
  const { pathname } = useLocation();
  const [countryInfo, setcountryInfo] = useState({});
  const [search, setSearch] = useState("");

  const returnDetailName = (value) => {
    const name = value.split(" ");
    if (name.length > 2) {
      return {
        lastName: name[0] + " " + name[1],
        firstName: name[2],
      };
    } else {
      return {
        lastName: name[0],
        firstName: name[1],
      };
    }
  };

  // Fonction lors de la sélection d'un contact
  const onContactSelect = useCallback((contact) => {
    const { lastName, firstName } = returnDetailName(contact?.fullname);
    let newValue = {
      ...contact,
      name: contact.fullname,
      email: contact.email1,
      fullname: contact.fullname,
      phone1: contact.telephone1,
      phone2: contact.telephone2,
      civility: contact.civility ?? "M",
      contact_id: contact.id,
      phonecode: contact.num_code,
      postalCode: contact.postal_code,
      firstName: firstName ?? contact?.firstName,
      lastName: lastName ?? contact?.lastName,
      country: getCountryNameOrCode(contact?.country),
    };

    setUserData(
      {
        ...newValue,
      },
      contactType,
      shippingProcess
    );
  }, []);

  // Fonction pour récupérer le code postal à partir du code pays
  const fetchPhonecode = async (countryCode) => {
    try {
      if (
        countryCode &&
        countryCode !== null &&
        countryCode !== undefined &&
        countryCode !== ""
      ) {
        const response = await axios.get(
          `https://restcountries.com/v3.1/alpha/${
            countryCode ? countryCode : "fr"
          }`
        );
        const countryData = response.data[0];

        if (countryData && countryData.idd) {
          const iddRoot = countryData.idd.root;
          const iddSuffixes = countryData.idd.suffixes[0];

          const completeIdd = `${iddRoot}${iddSuffixes}`;
          return completeIdd;
        } else {
          console.log("Country code not found");
        }
      }
    } catch (error) {
      console.error("Error fetching country IDD:", error);
    }
  };

  // Fonction pour récupérer le code postal à partir du code pays
  const handlePhoneCode = (countryCode) => {
    if (countryCode) {
      fetchPhonecode(countryCode)
        .then((completeIdd) => {
          updateUserData("phonecode", completeIdd);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  // Réinitialisation de l'état de l'utilisateur
  const clearAll = () => {
    resetUserData(contactType, shippingProcess);
  };

  // Mise à jour de l'état de l'utilisateur
  const updateUserData = (dataKey, value) => {
    if (
      errorInformation.field === dataKey ||
      errorInformation.field === "name"
    ) {
      resetErrorField();
    }
    updateUserDat(dataKey, value, contactType, shippingProcess);
  };

  const handleFectValidStepper = () => {
    const { valid, error, errorData } = VerifyUserStep(UserStateData);

    if (valid) {
      nextStepper();
    } else {
      setMessageError({
        ...errorData,
        contactType: contactType,
        shippingProcess: shippingProcess,
      });
      toast.error(errorData.message, {
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    if (countryInfo) {
      handlePhoneCode(countryInfo.value);
    }
  }, [countryInfo]);

  const isError =
    errorInformation.contactType === contactType &&
    errorInformation.shippingProcess === shippingProcess;

  return (
    <div className="content">
      <div className="space-y-3 card-box">
        <div className="grid-cols-3 gap-2 space-y-2 md:space-y-0 md:grid ">
          <div className="">
            <CivilitySelector
              showLabel={false}
              disabled={false}
              value={UserStateData?.civility ?? "M"}
              onChange={(v) => updateUserData("civility", v)}
            />
          </div>
          <div className="">
            <ContactFormInput
              placeholder="RECHERCHER CONTACT"
              value={search}
              onChange={setSearch}
              onSelect={onContactSelect}
              attribute={"company"}
              client={role === "client" ? true : false}
            />
          </div>
          <div className="">
            <ButtonComponent title={"NOUVELLE FICHE"} onClick={clearAll} />
          </div>
        </div>

        {/*  */}
        <div className={"grid-cols-2 gap-2 space-y-2 md:space-y-0 md:grid"}>
          {/* nom */}
          <div className="grid-cols-2 gap-2 space-y-2 md:space-y-0 md:grid">
            <InputNomSearch
              placeholder="NOM*"
              value={UserStateData?.firstName}
              onChange={(value) => {
                updateUserData("firstName", value);
              }}
              onSelect={onContactSelect}
              attribute={"fullname"}
              client={role === "client" ? true : false}
              isError={errorInformation.field === "firstName" && isError}
              errorMessage={errorInformation.message}
            />

            {/* prenom */}
            <div className="">
              <InputNomSearch
                placeholder={"PRéNOM*".toUpperCase()}
                value={UserStateData?.lastName}
                onChange={(v) => {
                  updateUserData("lastName", v);
                }}
                onSelect={onContactSelect}
                attribute={"fullname"}
                client={role === "client" ? true : false}
                isError={errorInformation.field === "lastName" && isError}
                errorMessage={errorInformation.message}
              />
            </div>
          </div>

          {/* address1 */}
          <div className="">
            <InputComponent
              value={UserStateData?.address1}
              onChange={(valeur) => updateUserData("address1", valeur)}
              errorMessage={errorInformation.message}
              placeholder={"Adresse*".toUpperCase()}
              label=""
              withLabel={false}
              isError={errorInformation.field === "address1" && isError}
            />
          </div>

          {/* entreprise */}
          <div className="">
            <InputNomSearch
              placeholder="ENTREPRISE (FACULTATIVE)"
              value={UserStateData?.company}
              onChange={(v) => {
                updateUserData("company", v !== "" ? v : "");
              }}
              onSelect={onContactSelect}
              attribute={"company"}
              client={role === "client" ? true : false}
              isError={errorInformation.field === "company" && isError}
              errorMessage={errorInformation.message}
            />
          </div>

          {/* address2 */}
          <InputComponent
            onChange={(valeur) => {
              updateUserData("address2", valeur);
            }}
            errorMessage={errorInformation.message}
            placeholder={"Complément d'adresse".toUpperCase()}
            value={UserStateData?.address2}
            label=""
            withLabel={false}
            isError={errorInformation.field === "address2" && isError}
          />
        </div>

        {/* country postalCode city */}
        <div className={"grid-cols-2 gap-2 space-y-2 md:space-y-0 md:grid"}>
          <div className=" pe-0">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <CountriesSelector
                    selectedValue={
                      UserStateData?.country
                        ? UserStateData?.country.toLowerCase()
                        : "fr"
                    }
                    onSelect={(v) => {
                      if (v.label) {
                        updateUserData("country", v.label);
                        // updateUserData("country", v.label);
                        setcountryInfo(v);
                      }
                    }}
                    showLabel={false}
                    isError={errorInformation.field === "country" && isError}
                    errorMessage={errorInformation.message}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex" style={{ gap: 5 }}>
                  <div className="form-group" style={{ flex: 4 }}>
                    <ZipCodesSelector
                      defaultValue={UserStateData?.postalCode}
                      country_code={
                        getCountryNameOrCode(
                          getCountryNameOrCode(UserStateData?.country),
                          true
                        ) ?? "fr"
                      }
                      onSelect={(e) => {
                        updateUserData("postalCode", e);
                      }}
                      showLabel={false}
                      isError={
                        errorInformation.field === "postalCode" && isError
                      }
                      errorMessage={errorInformation.message}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <CitiesSelector
                    defaultValue={UserStateData?.city}
                    country_code={getCountryNameOrCode(
                      getCountryNameOrCode(UserStateData?.country || "fr"),
                      true
                    )}
                    postalCode={UserStateData?.postalCode}
                    onSelect={(e) => {
                      updateUserData("city", e);
                    }}
                    isError={errorInformation.field === "city" && isError}
                    errorMessage={errorInformation.message}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className={"row"}>
              <div className="col-12">
                {/* expeditor?.phonecode */}
                <div className="form-group" style={{ flex: 1 }}>
                  <PhoneInputComponent
                    isChangeCountry={changeCountry}
                    value={UserStateData?.phone1}
                    country={UserStateData?.country}
                    onChange={(value, country, e, formattedValue) => {
                      updateUserData(
                        "phone1",
                        formattedValue
                        // {key2: "phonecode", value2: country?.dialCode }
                      );
                    }}
                    errorInformation={errorInformation}
                  />
                  {errorInformation.field === "phone1" && isError && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errorInformation.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <PhoneInput
                    country={
                      getCountryNameOrCode(
                        getCountryNameOrCode(UserStateData?.country),
                        true
                      ) ?? "fr"
                    }
                    value={UserStateData?.phone2}
                    onChange={(value, country, e, formattedValue) => {
                      updateUserData("phone2", formattedValue);
                    }}
                    enableSearch={true}
                    inputClass={"form-controle"}
                    countryCodeEditable={true}
                    disableDropdown={false}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <InputComponent
                    type={"email"}
                    value={UserStateData?.email?.toLowerCase()}
                    onChange={(valeur) => {
                      let newValue = valeur?.toLowerCase();
                      updateUserData("email", newValue);
                    }}
                    errorMessage={errorInformation.message}
                    placeholder={"EMAIL*"}
                    label=""
                    withLabel={false}
                    isError={errorInformation.field === "email" && isError}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"col-span-2"}>
            {UserStateData?.id ? (
              <>
                {" "}
                <div className="">
                  <div className="form-group">
                    <div className="checkbox">
                      <label className={"selection"}>
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={(e) => {
                            if (UserStateData?.id) {
                              updateUserData(
                                "update-contact",
                                !UserStateData["update-contact"] ? 1 : 0
                              );
                            } else {
                              toast.error(
                                "Le contact n'existe pas encore .Veillez plutôt cocher la case  ENREGISTRER  CE CONTACT DANS MON CARNET D’ADRESSES",
                                {
                                  position: "top-center",
                                }
                              );
                            }
                          }}
                          checked={UserStateData["update-contact"]}
                        />{" "}
                        MODIFIER CE CONTACT DANS MON CARNET D’ADRESSES
                      </label>
                    </div>
                  </div>
                </div>{" "}
              </>
            ) : (
              <>
                <div className="">
                  <div className="form-group">
                    <div className="checkbox">
                      <label className={"selection"}>
                        <input
                          // save-contact
                          onChange={(e) => {
                            if (!UserStateData?.id) {
                              updateUserData(
                                "save-contact",
                                !UserStateData["save-contact"] ? 1 : 0
                              );
                            } else {
                              toast.error(
                                "Le contact existe déjà .Veillez plutôt cocher la case  MODIFIER CE CONTACT DANS MON CARNET D’ADRESSES",
                                {
                                  position: "top-center",
                                }
                              );
                            }
                          }}
                          checked={UserStateData?.["save-contact"] || 0}
                          type="checkbox"
                          name="checkbox"
                        />{" "}
                        ENREGISTRER CE CONTACT DANS MON CARNET D’ADRESSES
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* button */}

          {shippingProcess === "normal" && (
            <>
              <div>
                <ButtonComponent
                  title={"Précédent"}
                  onClick={() =>
                    toggleAccordion(contactType === "expeditor" ? 1 : 2)
                  }
                />
              </div>
              <div>
                <ButtonComponent
                  title={"Suivant"}
                  onClick={handleFectValidStepper}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
