import React from "react";
import DashLayout from "../dashboard/DashLayout";
import { Outlet } from "react-router-dom";
import { ClientParisWrapper, useAppContext } from "../../context";
import { opsParisMenu } from "../../core/menu/ops/_paris.menu";
import { opsMedicalMenu } from "../../core/menu/ops/_medical.menu";
import { opsServiceMenu } from "../../core/menu/ops/_service.menu";

export const OpsDashLayout = ({ userRole, spaceName }) => {

  const removeEmballageTague = () => {

    let menuList =
      spaceName === "paris"
        ? opsParisMenu
        : spaceName === "medical"
        ? opsMedicalMenu
        : opsServiceMenu;

    return menuList;
  };
  return (
    <section className={"0"}>
      {/*  interfac = "client" type= "medical" */}
      <ClientParisWrapper userRole={userRole} spaceName={spaceName}>
        <DashLayout
          space={spaceName}
          navigation={removeEmballageTague()}
        >
          <Outlet />
        </DashLayout>
      </ClientParisWrapper>
    </section>
  );
};
