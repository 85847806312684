import React, { useCallback, useEffect, useState } from "react";
import UsersSelector from "../../Utils/Selectors/UsersSelector";
import opsService from "../../../services/ops/opsService";
import { useBasicFormValues } from "../../../hooks/useFormValues";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { validatePodData } from "../../../utils/validators";
import { getTimeFromDate } from "../../../utils";
import DriversSelector from "../../Utils/Selectors/DriversSelector";
import useDisclosure from "../../../hooks/useDisclosure"
import ModalComponent from "../../Utils/Modal/Index";
import CoursierSelector from "../../Utils/Selectors/CoursierSelector";
import { useDispatch, useSelector } from "react-redux";
import ExpeditionService from "../../../services/expedition/ExpeditionService";
import { setExpedition } from "../../../features/slices/expeditionSlice";
import { isArray } from "lodash";
import { DateTime } from 'luxon';
import { convertirHeureClientEnFrance, urlSplit } from "../../../utils/Functions/otherFunctions";
import { useAppContext } from "../../../context";
import { ShippingDetails } from "../../../store/shippingDetails";

export const PodTabsComponent=({ shipping_id }) =>{
    const initialState = {
        shipping_id,
        signature: "",
        coursier: "",
        date: "",
        heurs: "",
        type: "",
        need_archive: 1
    }
    const expeditionDetails = useSelector(state => state.expedition.expeditions);
    const expeditionData = ShippingDetails((state)=>state.details);
    console.log(expeditionData)
    const dispatch = useDispatch();
    const { data: podData, setFormValue, setData } = useBasicFormValues(initialState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditting, setIsEditting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [cancelReason, setCancelReason] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [pod, setPod] = useState(null);
    const { isAuth, setIsAuth } = useAppContext();

    const navigate = useNavigate()

    const { error: _err } = validatePodData(podData);


    useEffect(() => {
        const d = new Date();

        const date = d.toISOString().split("T")[0]
        const heure = getTimeFromDate()

        setFormValue("date", date)
        setFormValue("heurs", heure)
    }, [])

    //console.log({ podData })

    useEffect(() => {
        const getPod = async () => {
            if (shipping_id) {
                try {
                    setIsLoading(true)
                    const { data } = await opsService.getPod({ shipping_id });

                    if (data.success) {
                        if (data.data.length > 0) {
                            const _data = data.data[0]
                            const p = isArray(_data) ? null : _data /* || {} */
                            setPod(p);
                            setData(_p => ({
                                ..._p,
                                ...p
                            }))
                            ExpeditionService.getExpeditionDetails({ ref: expeditionData.ref }).then(
                                (data) => {
                                    const { data: expedition } = data.data;
                                    dispatch(setExpedition(expedition));
                                },
                                (error) => {
                                    console.log(error);
                                }
                            );

                        }
                    } else {
                        ExpeditionService.getExpeditionDetails({ ref: expeditionData.ref }).then(
                            (data) => {
                                const { data: expedition } = data.data;
                                dispatch(setExpedition(expedition));
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                    }
                } catch (error) {
                    console.error(error)

                } finally {
                    setIsLoading(false)
                }
            }
        }

        getPod()
    }, [shipping_id])

    useEffect(() => {
        if (shipping_id) {
            setFormValue("shipping_id", shipping_id)
        }

        setFormValue("type", isAuth?.spaceName)

    }, [shipping_id])

    const handleSubmit = useCallback(async () => {
        try {
            if (_err) {
                toast.error(
                    `${_err}`,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );

                return;
            }
            const submitData = {
                ...podData,
                //shipping_id: expedition.id,
            }

            if (pod) {
                submitData.pod_id = pod.id
            }
            delete submitData.created_at
            delete submitData.updated_at
            if (pod) {
                delete submitData.id
            }


            //console.log({ submitData })
            setIsSubmitting(true)
            const { data } = pod ? await opsService.updatePod(submitData) : await opsService.addPod(submitData);
            //console.log({ data })
            if (data.success) {
                setPod(data.data)
                toast.success(
                    `POD ${pod ? "modifié" : "enregistré"} avec succès`,
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } catch (error) {
            console.error(error)
            if (error.response?.status === 400) {
                //console.log("ERR", error.response.data)
                toast.error(
                    error.response.data.error,
                    {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            } else {
                toast.error(
                    "Une erreur est survenue",
                    {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } finally {
            setIsSubmitting(false)
        }
    }, [podData, pod, _err]);

    const onCoursierChange = (value) => {
        setFormValue("coursier", value)
    };


    const onCancel = useCallback(
        async (e) => {
            e?.preventDefault()
            const submitData = {
                shipping_id,
                annulee: 1,
                reason: cancelReason
            }
            try {

                setIsCancelling(true)
                const { data } = await opsService.cancelPod(submitData);
                if (data.success) {
                    //console.log(data.data)
                    //setSuivis(s => ([data.data, ...s]))
                    onClose()
                    setPod(null)
                    setData(initialState)
                    navigate(`/ops/${isAuth?.spaceName}/dash/annulee`)

                    toast.success(
                        `POD annulé avec succès`,
                        {
                            position: "bottom-center",
                            autoClose: 4000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsCancelling(false)
            }
        },
        [shipping_id, cancelReason]
    )

    const onDelete = useCallback(
        async () => {
            if (pod) {
                try {
                    setIsDeleting(true)
                    const { data } = await opsService.deletePod({ pod_id: pod.id });
                    if (data.success) {
                        setPod(null);
                        setData(initialState)
                        toast.success(
                            `POD supprimé avec succès`,
                            {
                                position: "bottom-center",
                                autoClose: 4000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    setIsDeleting(false)
                }
            }
        },
        [pod]
    )


    const podFetched = Boolean(pod);


    const { expedition } = urlSplit()

    //console.log({ valid, error, podFetched })

    // check an input's value has been changed compared to the data saved
    const updated = !isLoading && podFetched &&
        (pod.signature !== podData.signature ||
            pod.date !== podData.date ||
            pod.heurs !== podData.heurs ||
            pod.coursier !== podData.coursier)

    /*  const enableEdit = (valid && !isEditting && !updated)
     const enableDelete = (!isEditting && valid && podFetched) || isDeleting
     const enableCancel = isEditting || isCancelling || podFetched
     const enableSave = !isSubmitting || podFetched ? (valid && isEditting && updated) : valid; */

    /* 
    MODIFIER: 
     - s'il ya un ancien (pod)
     - et qu'on est pas en mode edit (isEditing)
     - et qu'il n'ya pas de soumission en cours
    */
    const enableEdit = pod && !isEditting/*  && !updated */ && !isSubmitting

    /* 
    SUPPRIMER:
    - s'il ya un ancien (pod) 
    - ou sans pod mais pas en cours de suppression null
    */

    const enableDelete = pod /* || (!pod && !isDeleting) */

    /* 
    ANNULER:
     - s'il ya un ancien pod 
     - si c'est pas en mode edit (!isEditing) null
    */
    const enableCancel = true //podFetched

    /* 
    ENREGISTRER:
    - pas d'ancien pod (pod) et pas en soumission (isSubmitting)
    - pas en cours de soumission (!isSubmitting)
    - ou s'il y a un ancien (pod) et en mode edit (isEditing) et une data a changé
    */
    const enableSave = (!pod && !isSubmitting) || pod && (isEditting && updated);

    /* 
     Désactiver les inputs:
     - s'il ya ancien pod (pod) et en mode edit (isEditing)
     - pas de pod
    */

    const inputEnabled = (pod && isEditting) || (!pod && !isLoading)

    const inputDisabled = !inputEnabled /* || isCancelling || isEditting; */

    const canSubmitCancel = cancelReason.length > 2

    const heureLocale = DateTime.local();

    return (
        <div className="">

            <div className="grid-cols-2 gap-2 space-y-2 md:grid md:space-y-0">
                <div className="">
                    <div className="form-group">
                        <label htmlFor="">Signature*</label>
                        <input
                            type="text"
                            name=""
                            id=""
                            className={`w-full ${inputDisabled ? "bg-gray-50" : "bg-white"} border border-gray-500 rounded-lg outline-none form-control`}
                            value={podData?.signature}
                            onChange={(e) => {
                                setFormValue("signature", e.target.value);
                            }}
                            disabled={inputDisabled}
                        />
                    </div>
                </div>

                <div className="">
                    <div className="form-group">
                        <CoursierSelector
                            onChange={onCoursierChange}
                            selectedValue={podData?.coursier}
                            type="coursiers"
                            isDisabled={inputDisabled}
                        />
                    </div>
                </div>
            </div>

            <div className="grid-cols-2 gap-2 space-y-2 md:grid md:space-y-0">
                <div className="">
                    <div className="form-group">
                        <label htmlFor="">Date*</label>
                        <input
                            type="date"
                            name=""
                            id=""
                            className={`w-full ${inputDisabled ? "bg-gray-50" : "bg-white"} border border-gray-500 rounded-lg outline-none form-control`}
                            value={podData?.date}
                            onChange={(e) => {
                                setFormValue("date", e.target.value);
                            }}
                            disabled={inputDisabled}
                        />
                    </div>
                </div>

                <div className="">
                    <div className={'grid grid-cols-2 gap-2 space-y-2  md:space-y-0'}>
                        <div className="">
                            <div className="">
                                <label htmlFor="">Heure Local*</label>
                                <input
                                    type="time"
                                    name=""
                                    id=""
                                    className={`w-full ${inputDisabled ? "bg-gray-50" : "bg-white"} border border-gray-500 rounded-lg outline-none form-control`}
                                    value={podData?.heurs}
                                    onChange={(e) => {
                                        setFormValue("heurs", e.target.value);
                                    }}
                                    disabled={inputDisabled}
                                />
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <label htmlFor="">Heure de France</label>
                                <input
                                    type="time"
                                    name=""
                                    id=""
                                    className={`w-full bg-gray-500 border border-gray-500 rounded-lg outline-none form-control disabled: `}
                                    value={convertirHeureClientEnFrance(podData?.heurs, Intl.DateTimeFormat().resolvedOptions().timeZone)}

                                    disabled={true}
                                />

                            </div>
                        </div>
                    </div>

                </div>
               
            </div>

            <div className="mt-3 row">
                <div className="grid-cols-8 gap-2 space-y-2 md:grid md:space-y-0">
                    {(expedition?.toUpperCase() === "EN-ATTENTE" || expedition?.toUpperCase() === "livre".toUpperCase() || expedition?.toUpperCase() === "en-cours".toUpperCase()) &&
                        <button
                            type={"button"}
                            className={`w-full px-4 mx-1 ${enableCancel ? "bg-black cursor-pointer" : "bg-gray-500 cursor-not-allowed"} text-white py-2 text-sm border rounded-lg`}
                            // disabled={!enableCancel}
                            onClick={() => {
                                if (true) {
                                    onOpen()
                                }
                            }}
                        >
                            Annuler
                        </button>

                    }

                    <button
                        type={"button"}
                        onClick={() => { setIsEditting(true) }}
                        disabled={!enableEdit}
                        className={`w-full px-4 mx-1 ${enableEdit ? "bg-black cursor-pointer" : "bg-gray-500 cursor-not-allowed"} text-white py-2 text-sm border rounded-lg`}
                    >
                        Modifier
                    </button>
                    <button
                        type={"button"}
                        onClick={onDelete}
                        disabled={!enableDelete}
                        className={`w-full px-4 mx-1 ${enableDelete ? "bg-black cursor-pointer" : "bg-gray-500 cursor-not-allowed"} text-white py-2 text-sm border rounded-lg`}
                    >
                        {isDeleting ? "Suppression..." : "Supprimer"}
                    </button>
                    <button
                        type={"button"}
                        className={`w-full px-4 mx-1 ${enableSave ? "bg-black cursor-pointer" : "bg-gray-500 cursor-not-allowed"} text-white py-2 text-sm border rounded-lg`}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Enrégistrement..." : "Enregistrer"}
                    </button>
                    <button
                        type={"button"}
                        className="w-full px-4 py-2 mx-1 text-sm text-white bg-black border rounded-lg "
                    >
                        Renvoyer mail
                    </button>
                </div>
            </div>


            <ModalComponent
                isOpen={isOpen}
                onClose={onClose}
                title={`Raison de l'annulation`}
            >

                <form className="w-full px-3 py-8">
                    <div className="flex flex-wrap justify-center mb-6 -mx-3">
                        <div className="w-full px-3 mb-6 md:w-2/3 md:mb-0">
                            <textarea
                                className="h-full min-h-[100px] w-full resize-none rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50 ring-1 ring-black border-black"
                                id="note"
                                placeholder="Pour quelle raison voulez-vous annuler?"
                                value={cancelReason}
                                onChange={(e) => {
                                    setCancelReason(e.target.value)
                                }}
                            ></textarea>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <button
                            onClick={onCancel}
                            type="submit"
                            className={`text-white ${canSubmitCancel ? "bg-black cursor-pointer hover:bg-gray-800" : "bg-slate-600 cursor-not-allowed"} focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
                            disabled={!canSubmitCancel}
                        >
                            {isCancelling ? "Annulation..." : "Valider"}
                        </button>
                    </div>
                </form>

            </ModalComponent>
        </div>
    );
}
