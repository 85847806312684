
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';

export const InputComponent3 = ({ type = "text", value, onChange, InputClassName = "rounded-md", errorMessage = '', placeholder = '', label = '', withLabel = false, title = '', children = (<></>), isError = false, capacity = 'cm', ...other }) => {

    return (
        <section>
            <div>
                {withLabel &&
                    <label htmlFor={label} className="block mb-1 text-lg font-normal leading-6">
                        {label}
                    </label>
                }

                {type !== 'textarea' &&
                    <>

                        {type === 'nombre' ?
                            (<div className="relative rounded-md ">
                                <input
                                    value={value}
                                    {...other}
                                    name={label}
                                    id={label}
                                    className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, `px-2 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none ${InputClassName}`)}
                                    placeholder={placeholder}
                                    defaultValue={value}
                                    aria-invalid="true"
                                    aria-describedby={label.replace(/ /g, '') + "-error"}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        let numericValue = inputValue.replace(/[^0-9]/g, '');
                                        onChange(numericValue);

                                    }
                                    }
                                />

                                {isError &&
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                        <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                                    </div>
                                }

                            </div>) : (type === 'file' ?
                                (<div className="relative rounded-md ">
                                    <input
                                        value={value}
                                        type={type}
                                        {...other}
                                        name={label}
                                        id={label}
                                        className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500 ": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError },
                                            `px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none `

                                        )}

                                        placeholder={placeholder}
                                        defaultValue={value}
                                        aria-invalid="true"
                                        aria-describedby={label.replace(/ /g, '') + "-error"}
                                        onChange={(e) => {
                                            console.log(e.target.files?.[0])
                                            onChange(e.target.files?.[0])
                                        }}
                                    />

                                    {isError &&
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                            <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                                        </div>
                                    }

                                </div>)
                                :
                                (type === "double" ? (
                                    <div className="relative mt-2 rounded-md shadow-sm">

                                        <input
                                            value={value}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                let numericValue = inputValue.replace(/[^0-9]/g, '');
                                                onChange(numericValue);

                                            }}
                                            type="text"
                                            name={placeholder}
                                            id={placeholder}
                                            className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, `px-2 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none ${InputClassName}`)}
                                            placeholder={placeholder}
                                            aria-describedby="price-currency"
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                {title}
                                            </span>
                                        </div>
                                    </div>
                                ) : (type === 'payment' ? (

                                    <div className="relative mt-2 rounded-md shadow-sm">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            {children}
                                        </div>
                                        <input
                                            value={value}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                let numericValue = inputValue.replace(/[^0-9]/g, '');
                                                onChange(numericValue);

                                            }}
                                            type="text"
                                            name={placeholder}
                                            id={placeholder}
                                            className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, `pl-14 block w-full border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none ${InputClassName}`)}
                                            placeholder={placeholder}
                                            aria-describedby="price-currency"
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                {title}
                                            </span>
                                        </div>
                                    </div>

                                ) :
                                    (type === "capacity" ? <>
                                        <div>

                                            <div className="mt-2">
                                                <div className={clsx({ "text-red-500 ring-red-500 bg-red-500 placeholder:text-red-500 focus:ring-red-500 outline-red-500 ": isError, " bg-gray-200 rounded-md outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-none": !isError }, "flex")} >
                                                    <input
                                                        id="search"
                                                        name="search"
                                                        type="number"
                                                        className="block min-w-0 grow px-3 py-1.5 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6 "
                                                    />
                                                    <div className="flex py-1.5 pr-1.5  ">
                                                        <kbd className="inline-flex items-center px-1 font-sans text-xs font-bold text-black rounded-lg">
                                                            {capacity}
                                                        </kbd>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                        <div className="relative rounded-md ">
                                            <input
                                                value={value}
                                                type={type}
                                                {...other}
                                                name={label}
                                                id={label}
                                                className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6 focus:outline-none")}
                                                placeholder={placeholder}
                                                defaultValue={value}
                                                aria-invalid="true"
                                                aria-describedby={label.replace(/ /g, '') + "-error"}
                                                onChange={(e) => onChange(e.target.value)}
                                            />

                                            {isError &&
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                    <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            }

                                        </div>
                                    )
                                )
                                ))
                        }
                    </>

                }

                {type === 'textarea' &&

                    <div className="relative rounded-md">
                        <textarea
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            rows={6}
                            name="comment"
                            id="comment"
                            placeholder={placeholder}
                            className={clsx({ "text-red-500 ring-red-500 placeholder:text-red-500 focus:ring-red-500": isError, "ring-gray-300 focus:ring-gray-50 bg-white focus:ring-1 focus:ring-inset": !isError }, "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6")}
                        ></textarea>
                    </div>


                }
                {isError &&

                    <p className="mt-2 text-sm text-red-600" id={label.replace(/ /g, '') + "-error"}>
                        {errorMessage}
                    </p>
                }

            </div>
        </section >
    );
}