import React, { useEffect, useState } from "react";
import logoParis from "./../assets/img/exnext-logo/paris.png";
import logoMedical from "./../assets/img/exnext-logo/medical.png";
import logoService from "./../assets/img/exnext-logo/service.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Head from "../components/HeadComponent/Head";
import { validateEmail } from "../utils/ExpressionReguliere";
import { roleTable, typeTable } from "../utils/SpaceData";
import { toast } from "react-toastify";
import errors from "../messages/errors.js";
import { LoadingComponent } from "../utils/loadingUtils.jsx";
import { authController } from "../controller/auth/authController.js";
import backgroundAdmin from "../assets/img/v1/18776165_6032877.jpg";
import { exnetSpaceColor } from "../utils/Functions/otherFunctions";
import BgNatureImg from "../assets/img/nature.jpg";
import BgNatureImg3 from "../assets/img/nature3.jpg";
import BgNatureImg4 from "../assets/img/nature4.jpg";
import BgNatureImg5 from "../assets/img/nature5.jpg";
import { isSelectedYears } from "../utils/Functions/otherFunctions";
import DecemnerImg_1 from "./../assets/img/december/december_1.jpg";
import DecemnerImg_3 from "./../assets/img/december/december_3.jpg";
import DecemnerImg_4 from "./../assets/img/december/december_4.jpg";
import DecemnerImg_5 from "./../assets/img/december/december_5.jpg";
import DecemnerImg_6 from "./../assets/img/december/december_6.jpg";
import DecemnerImg_7 from "./../assets/img/december/december_7.jpg";
import DecemnerImg_8 from "./../assets/img/december/december_8.jpg";
import DecemnerImg_9 from "./../assets/img/december/december_9.jpg";
import DecemnerImg_10 from "./../assets/img/december/december_10.jpg";
import DecemnerImg_11 from "./../assets/img/december/december_11.jpg";
import NewYearImg_1 from "./../assets/img/newyears/newyers_1.jpeg";
import NewYearImg_2 from "./../assets/img/newyears/newyers_2.jpeg";
import NewYearImg_3 from "./../assets/img/newyears/newyers_3.jpeg";
import NewYearImg_4 from "./../assets/img/newyears/newyers_4.jpeg";
import NewYearImg_5 from "./../assets/img/newyears/newyers_5.jpeg";
import NewYearImg_6 from "./../assets/img/newyears/newyers_6.jpeg";
import NewYearImg_7 from "./../assets/img/newyears/newyers_7.jpeg";
import NewYearImg_8 from "./../assets/img/newyears/newyers_8.jpeg";
import { InputNew } from "../components/inputNew.jsx";
import { LockClosedIcon } from "@heroicons/react/20/solid";

const tableImg = [
  BgNatureImg,
  BgNatureImg3,
  BgNatureImg4,
  BgNatureImg5,
  backgroundAdmin,
];
const decemberImg = [
  DecemnerImg_1,
  DecemnerImg_3,
  DecemnerImg_4,
  DecemnerImg_5,
  DecemnerImg_6,
  DecemnerImg_7,
  DecemnerImg_8,
  DecemnerImg_9,
  DecemnerImg_10,
  DecemnerImg_11,
];
const newYearsImage = [
  NewYearImg_1,
  NewYearImg_2,
  NewYearImg_3,
  NewYearImg_4,
  NewYearImg_5,
  NewYearImg_6,
  NewYearImg_7,
  NewYearImg_8,
];

// generate a random number between 0 and 3
const randomImage = () => {
  console.log(isSelectedYears(0), isSelectedYears(11));
  if (isSelectedYears(0)) {
    return newYearsImage[Math.floor(Math.random() * 7)];
  } else if (isSelectedYears(11)) {
    return decemberImg[Math.floor(Math.random() * 10)];
  } else {
    return tableImg[Math.floor(Math.random() * 3)];
  }
};

function AuthPage({ roleProps }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [interfac, setInterface] = useState("");

  const [backgroundState, setBackgroundState] = useState("");

  const [type, settype] = useState("");
  const { role, milieu } = useParams();
  const navigate = useNavigate();
  const [rollbackUrl, setRollbackUrl] = useState("");
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    let lien = window.location.pathname.split("/");
    if (lien.includes("ops")) {
      setBackgroundState(randomImage());
    } else if (lien.includes("admin")) {
      setBackgroundState(randomImage());
    } else {
      setBackgroundState(randomImage());
    }

    let roleValue = roleProps === "admin" ? roleProps : role;
    // Controle des paramètres de la route
    if (!roleTable.includes(roleValue) || !typeTable.includes(milieu)) {
      navigate("/not-found");
    }
    if (lien.length === 4) {
      console.log("SL", lien[1]);
      setInterface(lien[1]);
      settype(lien[2]);
    }

    let roles = JSON.parse(localStorage.getItem("persist:root"))
      ? JSON.parse(localStorage.getItem("persist:root")).roles
      : "";

    localStorage.clear();
    // if (roles.includes("ROLE_USER_PARIS")) {
    //   navigate("/client/paris/dash");
    // } else if (roles.includes("ROLE_USER_MEDICAL")) {
    //   navigate("/client/medical/dash");
    // } else if (roles.includes("ROLE_USER_SERVICE")) {
    //   navigate("/client/service/dash");
    // } else if (roles.includes("ROLE_OPS_PARIS")) {
    //   navigate("/ops/paris/dash");
    // } else if (roles.includes("ROLE_OPS_MEDICAL")) {
    //   navigate("/ops/medical/dash");
    // } else if (roles.includes("ROLE_OPS_SERVICE")) {
    //   navigate("/ops/service/dash");
    // } else if (
    //   roles.includes("ROLE_ADMIN") ||
    //   roles.includes("ROLE_PRESIDENT") ||
    //   roles.includes("ROLE_RESTRICTED")
    // ) {
    //   navigate("/admin/dash");
    // }
  }, []);

  const login = () => {
    if (validateEmail(email)) {
      if (password.length >= 8) {
        const toastsId = toast.loading("Authentification en cours ...", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        try {
          //const _interface = role === "exploitation" ? milieu : interfac
          const res = authController(email, password, interfac, type)
            .then((result) => {
              if (result.success) {
                toast.update(toastsId, {
                  render: "Authentification réussie",
                  type: "success",
                  isLoading: false,
                  autoClose: 1000,
                  position: "top-center",
                });

                let userData = result.data;
                const roles = userData.roles;
                let lien = window.location.pathname.split("/");
                lien.pop();

                setTimeout(() => {
                  if (
                    roles.includes("ROLE_USER_PARIS") &&
                    interfac === "client" &&
                    type === "paris"
                  ) {
                    navigate("/client/paris/dash");
                    // window.location.href = `${lien.join("/")}/dash`
                  } else if (
                    roles.includes("ROLE_USER_MEDICAL") &&
                    interfac === "client" &&
                    type === "medical"
                  ) {
                    // navigate(lien.join("/") + "/dash");
                    window.location.href = `${lien.join("/")}/dash`;
                  } else if (
                    roles.includes("ROLE_USER_SERVICE") &&
                    interfac === "client" &&
                    type === "service"
                  ) {
                    // navigate(lien.join("/") + "/dash");
                    window.location.href = `${lien.join("/")}/dash`;
                  } else if (
                    roles.includes("ROLE_OPS_PARIS") &&
                    interfac === "ops" &&
                    type === "paris"
                  ) {
                    // navigate(lien.join("/") + "/dash");
                    window.location.href = `${lien.join("/")}/dash`;
                  } else if (
                    roles.includes("ROLE_OPS_MEDICAL") &&
                    interfac === "ops" &&
                    type === "medical"
                  ) {
                    // navigate(lien.join("/") + "/dash");
                    window.location.href = `${lien.join("/")}/dash`;
                  } else if (
                    roles.includes("ROLE_OPS_SERVICE") &&
                    interfac === "ops" &&
                    type === "service"
                  ) {
                    // navigate(lien.join("/") + "/dash");
                    window.location.href = `${lien.join("/")}/dash`;
                  } else if (
                    roles.includes("ROLE_EXPLOITATION") &&
                    role === "exploitation"
                  ) {
                    // navigate(`/exploitation/${milieu}/drivers`);
                    window.location.href = `/exploitation/${milieu}/drivers`;
                  } else if (
                    (roles.includes("ROLE_ADMIN") ||
                      roles.includes("ROLE_PRESIDENT") ||
                      roles.includes("ROLE_RESTRICTED")) &&
                    (interfac === "" || interfac === "admin")
                  ) {
                    if (lien.length === 1) lien.push("admin");
                    // navigate(lien.join("/") + "/dash");
                    window.location.href = `${lien.join("/")}/dash`;
                  }
                }, 1000);
              } else {
                toast.update(toastsId, {
                  render: errors[result.data.response.data.error_code],
                  type: "error",
                  isLoading: false,
                  autoClose: 1000,
                  position: "top-right",
                });
              }
            })
            .catch((error) => {
              toast.update(toastsId, {
                render: "Une erreur lors de la connexion au serveur",
                type: "error",
                isLoading: false,
                autoClose: 1000,
                position: "top-right",
              });
            });
        } catch (error) {
          toast.update(toastsId, {
            render: "Une erreur lors de la connexion au serveur",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            position: "top-right",
          });
        }
      } else {
        toast.warning("Invalide mot de passe", {
          position: "top-right",
          theme: "light",
        });
      }
    } else {
      toast.warning("Invalide email", {
        position: "top-right",
        theme: "light",
      });
    }
  };

  const [first, setfirst] = useState();

  const chargement = () => {
    setOverlay(true);
    setfirst(
      toast.loading("Soumission du formulaire", {
        position: "top-right",
        theme: "light",
      })
    );
  };

  const refresh = () =>
    toast.update(first, {
      render: "All is good",
      type: "success",
      isLoading: false,
      autoClose: 5000,
    });

  if (overlay) {
    setTimeout(() => {
      refresh();

      setTimeout(() => {
        setOverlay(false);
      }, 5000);
    }, 10000);
  }

  return (
    <>
      <Head espace={role === undefined ? "Admin" : role}>
        <title>{`Exnet-${role === undefined ? "Admin" : role.charAt(0) + role.slice(1)
          } | Auth`}</title>
      </Head>
      <LoadingComponent overlay={overlay} />
      <div
        className="h-full main-wrapper account-wrapper bg-wrapper"
        style={{
          backgroundImage: `url(${backgroundState})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="flex-col items-center justify-center h-screen pt-5 account-page d-flex">
          <div className="mt-5 account-center">

            <div className="account-box">
              <div className="flex items-center justify-center align-middle account-logo">
                <Link to={"/"} className={"text-center"}>
                  <img
                    // style={{ maxWidth: "275px" }}
                    className={"w-44"}
                    src={
                      milieu === "medical"
                        ? logoMedical
                        : milieu === "service"
                          ? logoService
                          : logoParis
                    }
                    alt="Logo"
                  />
                </Link>
              </div>

              <div className="login-header">
                <h3 className="text-center">Connexion</h3>
              </div>
              <div className="mt-5 space-y-3">
                <div>
                  <InputNew
                    key={'email'}
                    type="email"
                    placeholder="Email"
                    label={"E-mail"}
                    withLabel={true}
                    autoFocus=""
                    value={email}
                    onChange={(value) => {
                      setEmail(value);
                    }}
                  />
                </div>
                <div>
                  <InputNew
                    key={'password'}
                    type="password"
                    placeholder="Mot de passe"
                    label={"Mot de passe"}
                    withLabel={true}
                    autoFocus=""
                    value={password}
                    onChange={(value) => {
                      setPassword(value);
                    }}
                  />
                </div>

                <div className="flex items-baseline justify-between">
                  <div className="remember-me">
                    <label
                      className="mb-0 custom_check me-2 d-inline-flex remember-me"
                      style={{ fontSize: "12px" }}
                    >
                      {" "}
                      {/* Se souvenir de moi */}
                      Mémoriser mon identifiant
                      <input type="checkbox" name="radio" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Link
                    className={"flex gap-1 items-baseline "}
                    style={{ fontSize: "12px" }}
                    to={
                      roleProps === "admin"
                        ? `/${"admin"}/forgot-password`
                        : `/${role}/${milieu}/forgot-password`
                    }
                  >
                    <LockClosedIcon className={"w-4 h-4"} style={{
                      color: `${exnetSpaceColor(type)}`
                    }} />
                    Vous avez oublié votre mot de passe ?
                  </Link>
                </div>
                <div>
                  <p className="text-sm">
                    Pour optimiser l'utilisation de notre site, nous vous
                    conseillons de vous connecter avec Chrome et Firefox.
                  </p>
                </div>
                <div className="text-center form-group">
                  <button
                    type="button"
                    className="btn account-btn"
                    onClick={login}
                    style={{
                      color: "#fff",
                      backgroundColor: `${exnetSpaceColor(type)}`,
                    }}
                  >
                    Se connecter <i className="fas fa-arrow-right ms-1"></i>
                  </button>

                  <a
                    className={"cursor-pointer px-5"}
                    href
                    rel="noreferrer"
                    onClick={() =>
                      navigate(
                        role === "admin"
                          ? "/admin/auth"
                          : role === "ops"
                            ? "/ops"
                            : "/"
                      )
                    }
                    style={{ border: "none" }}
                  >
                    Retour a l'accueil
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-overlay" data-reff=""></div>
    </>
  );
}

export default AuthPage;
