import { create } from "zustand";
import { trierTableau } from "../utils/filterFunction";
import { adminPackageService } from "../services/admin/packageService";
import { returnValue } from "../utils/Functions/functions";
import clientService from "../services/client/clientService";

export const TableDataStore = create((set) => ({
  dataListConfig: {
    page: 1,
    totalItems: 0,
    perPageItems: 25,
    isSearch: false,
    searchValue: "",
  },
  dataList: [],
  data: {},
  setDataContent: (newData) =>
    set((state) => ({
      data: { ...newData },
    })),
  modifyData: (newData) =>
    set((state) => ({
      data: { ...state.data, ...newData },
    })),
  updateData: (id, newData) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = { ...state.dataList[index], ...newData };

      // Créer une nouvelle liste d'adresses avec l'adresse mise à jour
      const updatedDataList = [...state.dataList];
      updatedDataList[index] = updatedData;

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedDataList] };
    }),
  deleteData: (id) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = state.dataList.filter((item) => item?.id !== id);

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedData] };
    }),
  addData: (newData) =>
    set((state) => ({
      dataList: [...state.dataList, { ...newData }],
    })),
  setDataList: (newData) =>
    set((state) => ({
      dataList: [...newData],
    })),
  filterListe: (filterBy, filterOrder) =>
    set((state) => {
      let newListe = trierTableau(state.dataList, filterBy, filterOrder);

      return { dataList: [...newListe] };
    }),
  setDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...newData },
    })),
  modifyDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...state.dataListConfig, ...newData },
    })),
  resetData: () =>
    set((state) => {
      return { dataList: [] };
    }),
}));

export const ContactDataStore = create((set) => ({
  dataListConfig: {
    page: 1,
    totalItems: 0,
    perPageItems: 25,
    isSearch: false,
    searchValue: "",
  },
  dataList: [],
  data: {},
  setDataContent: (newData) =>
    set((state) => ({
      data: { ...newData },
    })),
  modifyData: (newData) =>
    set((state) => ({
      data: { ...state.data, ...newData },
    })),
  updateData: (id, newData) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = { ...state.dataList[index], ...newData };

      // Créer une nouvelle liste d'adresses avec l'adresse mise à jour
      const updatedDataList = [...state.dataList];
      updatedDataList[index] = updatedData;

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedDataList] };
    }),
  deleteData: (id) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = state.dataList.filter((item) => item?.id !== id);

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedData] };
    }),
  addData: (newData) =>
    set((state) => ({
      dataList: [...state.dataList, { ...newData }],
    })),
  setDataList: (newData) =>
    set((state) => ({
      dataList: [...newData],
    })),
  filterListe: (filterBy, filterOrder) =>
    set((state) => {
      let newListe = trierTableau(state.dataList, filterBy, filterOrder);

      return { dataList: [...newListe] };
    }),
  setDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...newData },
    })),
  modifyDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...state.dataListConfig, ...newData },
    })),
  resetData: () =>
    set((state) => {
      return { dataList: [] };
    }),
}));

export const EmballageDataStore = create((set) => ({
  dataListConfig: {
    page: 1,
    totalItems: 0,
    perPageItems: 25,
    isSearch: false,
    searchValue: "",
  },
  isLoading: false,
  selectorListData: [],
  dataList: [],
  data: {},
  modifyData: (keys, value) =>
    set((state) => {
      return {
        data: {
          ...state.data,
          [keys]: value,
        },
      };
    }),

  setDataContent: (newData) =>
    set((state) => ({
      data: { ...newData },
    })),

  updateData: (id, newData) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = { ...state.dataList[index], ...newData };

      // Créer une nouvelle liste d'adresses avec l'adresse mise à jour
      const updatedDataList = [...state.dataList];
      updatedDataList[index] = updatedData;

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedDataList] };
    }),
  deleteData: (id) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = state.dataList.filter((item) => item?.id !== id);

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedData] };
    }),
  addData: (newData) =>
    set((state) => ({
      dataList: [...state.dataList, { ...newData }],
    })),
  setDataList: (newData) =>
    set((state) => ({
      dataList: [...newData],
    })),
  filterListe: (filterBy, filterOrder) =>
    set((state) => {
      let newListe = trierTableau(state.dataList, filterBy, filterOrder);

      return { dataList: [...newListe] };
    }),
  setDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...newData },
    })),
  modifyDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...state.dataListConfig, ...newData },
    })),
  fetchList: async (perPage, page, role) => {
    try {
      set((state) => ({
        isLoading: true,
      }));

      if (role === "client") {
        clientService
          .getPackages({
            per_page: perPage,
            page: page,
          })
          .then(
            (data) => {
              const response = data.data.data.data;

              set((state) => ({
                isLoading: false,
                dataList: [...response],
              }));
            },
            (error) => {
              set((state) => ({
                isLoading: true,
              }));
              console.log(error);
            }
          );
      } else {
        adminPackageService
          .getPackages({
            per_page: perPage,
            page: page,
          })
          .then(
            (data) => {
              const response = data.data.data.data;

              set((state) => ({
                isLoading: false,
                dataList: [...response],
              }));
            },
            (error) => {
              set((state) => ({
                isLoading: true,
              }));
              console.log(error);
            }
          );
      }
    } catch (error) {
      set((state) => ({
        isLoading: true,
      }));
      console.error("Erreur lors de la récupération des détails :", error);
    }
  },
  fetchListByTypeId: async (perPage, page, typeEmballage, role) => {
    try {
      set((state) => ({
        isLoading: true,
      }));
      if (role === "client") {
        clientService
          .getPackagesByTypeId({
            per_page: perPage,
            page: page,
            id: typeEmballage,
          })
          .then(
            (data) => {
              const response = data.data.data.data;
              let table = [];
              response.forEach((element) => {
                table.push({
                  value: element.id,
                  label: `${element.nom_produit}(Volume:${element.volume_utile}L , Poids:${element.poids}kg)`, //${element.nom_produit}
                  ...element,
                });
              });

              set((state) => ({
                isLoading: false,
                selectorListData: table,
                dataList: [...response],
              }));
            },
            (error) => {
              set((state) => ({
                isLoading: true,
              }));
              console.log(error);
            }
          );
      } else {
        adminPackageService
          .getPackagesByTypeIdUser({
            per_page: perPage,
            page: page,
            id: typeEmballage,
          })
          .then(
            (data) => {
              const response = data.data.data.data;
              let table = [];
              response.forEach((element) => {
                table.push({
                  value: element.id,
                  label: `${element.nom_produit}(Volume:${element.volume_utile}L , Poids:${element.poids}kg)`, //${element.nom_produit}
                  ...element,
                });
              });

              set((state) => ({
                isLoading: false,
                selectorListData: table,
                dataList: [...response],
              }));
            },
            (error) => {
              set((state) => ({
                isLoading: true,
              }));
              console.log(error);
            }
          );
      }
    } catch (error) {
      set((state) => ({
        isLoading: true,
      }));
      console.error("Erreur lors de la récupération des détails :", error);
    }
  },
  resetData: () =>
    set((state) => {
      return { dataList: [] };
    }),
}));

export const TypeEmballageDataStore = create((set) => ({
  dataListConfig: {
    page: 1,
    totalItems: 0,
    perPageItems: 25,
    isSearch: false,
    searchValue: "",
  },
  dataList: [],
  data: {},
  selectorListData: [],
  isLoading: false,
  modifyData: (keys, value) =>
    set((state) => {
      return {
        data: {
          ...state.data,
          [keys]: value,
        },
      };
    }),

  setDataContent: (newData) =>
    set((state) => ({
      data: { ...newData },
    })),
  // modifyData: (newData) => set((state) => ({
  //     data: { ...state.data, ...newData }
  // })),
  updateData: (id, newData) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = { ...state.dataList[index], ...newData };

      // Créer une nouvelle liste d'adresses avec l'adresse mise à jour
      const updatedDataList = [...state.dataList];
      updatedDataList[index] = updatedData;

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedDataList] };
    }),
  deleteData: (id) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = state.dataList.filter((item) => item?.id !== id);

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedData] };
    }),
  addData: (newData) =>
    set((state) => ({
      dataList: [...state.dataList, { ...newData }],
    })),
  setDataList: (newData) =>
    set((state) => ({
      dataList: [...newData],
    })),
  filterListe: (filterBy, filterOrder) =>
    set((state) => {
      let newListe = trierTableau(state.dataList, filterBy, filterOrder);

      return { dataList: [...newListe] };
    }),
  setDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...newData },
    })),
  modifyDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...state.dataListConfig, ...newData },
    })),

  fetchList: async (perPage, page, role) => {
    try {
      set((state) => ({
        isLoading: true,
      }));
      if (role === "client") {
        clientService
          .getsPackageType({
            perPage: perPage,
            page: page,
          })
          .then(
            (data) => {
              const response = data.data.data.data;
              let table = [];
              response.forEach((element) => {
                table.push({
                  value: element.id,
                  label: element.libelle,
                });
              });

              set((state) => ({
                selectorListData: table,
                isLoading: false,
                dataList: [...response],
              }));
            },
            (error) => {
              console.log(error);
            }
          );
      } else {
        adminPackageService
          .getsPackageType({
            perPage: perPage,
            page: page,
          })
          .then(
            (data) => {
              const response = data.data.data.data;
              let table = [];
              response.forEach((element) => {
                table.push({
                  value: element.id,
                  label: element.libelle,
                });
              });

              set((state) => ({
                selectorListData: table,
                isLoading: false,
                dataList: [...response],
              }));
            },
            (error) => {
              console.log(error);
            }
          );
      }
    } catch (error) {
      set((state) => ({
        isLoading: false,
      }));
      console.error("Erreur lors de la récupération des détails :", error);
    }
  },
  resetData: () =>
    set((state) => {
      return { dataList: [] };
    }),
}));

export const TypeDiagnoBagDataStore = create((set) => ({
  dataListConfig: {
    page: 1,
    totalItems: 0,
    perPageItems: 25,
    isSearch: false,
    searchValue: "",
  },
  isLoading: false,
  selectorListData: [],
  dataList: [],
  data: {},
  modifyData: (keys, value) =>
    set((state) => {
      return {
        data: {
          ...state.data,
          [keys]: value,
        },
      };
    }),

  setDataContent: (newData) =>
    set((state) => ({
      data: { ...newData },
    })),
  // modifyData: (newData) => set((state) => ({
  //     data: { ...state.data, ...newData }
  // })),
  updateData: (id, newData) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = { ...state.dataList[index], ...newData };

      // Créer une nouvelle liste d'adresses avec l'adresse mise à jour
      const updatedDataList = [...state.dataList];
      updatedDataList[index] = updatedData;

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedDataList] };
    }),
  deleteData: (id) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = state.dataList.filter((item) => item?.id !== id);

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedData] };
    }),
  addData: (newData) =>
    set((state) => ({
      dataList: [...state.dataList, { ...newData }],
    })),
  setDataList: (newData) =>
    set((state) => ({
      dataList: [...newData],
    })),
  filterListe: (filterBy, filterOrder) =>
    set((state) => {
      let newListe = trierTableau(state.dataList, filterBy, filterOrder);

      return { dataList: [...newListe] };
    }),
  setDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...newData },
    })),
  modifyDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...state.dataListConfig, ...newData },
    })),

  fetchList: async (perPage, page, role) => {
    try {
      set((state) => ({
        isLoading: true,
      }));

      if (role === "client") {
        clientService
          .getsDiagnobag({
            per_page: perPage,
            page: page,
          })
          .then(
            (data) => {
              const response = data.data.data.data;
              let table = [];
              response.forEach((element) => {
                table.push({
                  value: element.id,
                  label: `${element.libelle}(${returnValue(
                    element.longueur
                  )}x${returnValue(element.largeur)}cm)`,
                });
              });

              set((state) => ({
                isLoading: false,
                selectorListData: table,
                dataList: [...response],
              }));
            },
            (error) => {
              set((state) => ({
                isLoading: false,
              }));
              console.log(error);
            }
          );
      } else {
        adminPackageService
          .getsDiagnobag({
            per_page: perPage,
            page: page,
          })
          .then(
            (data) => {
              const response = data.data.data.data;
              let table = [];
              response.forEach((element) => {
                table.push({
                  value: element.id,
                  label: `${element.libelle}(${returnValue(
                    element.longueur
                  )}x${returnValue(element.largeur)}cm)`,
                });
              });

              set((state) => ({
                isLoading: false,
                selectorListData: table,
                dataList: [...response],
              }));
            },
            (error) => {
              set((state) => ({
                isLoading: false,
              }));
              console.log(error);
            }
          );
      }
    } catch (error) {
      set((state) => ({
        isLoading: false,
      }));
      console.error("Erreur lors de la récupération des détails :", error);
    }
  },
  resetData: () =>
    set((state) => {
      return { dataList: [] };
    }),
}));

export const TypeSondeDataStore = create((set) => ({
  dataListConfig: {
    page: 1,
    totalItems: 0,
    perPageItems: 25,
    isSearch: false,
    searchValue: "",
  },
  selectorListData: [],
  isLoading: false,
  dataList: [],
  data: {},
  modifyData: (keys, value) =>
    set((state) => {
      return {
        data: {
          ...state.data,
          [keys]: value,
        },
      };
    }),

  setDataContent: (newData) =>
    set((state) => ({
      data: { ...newData },
    })),
  // modifyData: (newData) => set((state) => ({
  //     data: { ...state.data, ...newData }
  // })),
  updateData: (id, newData) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = { ...state.dataList[index], ...newData };

      // Créer une nouvelle liste d'adresses avec l'adresse mise à jour
      const updatedDataList = [...state.dataList];
      updatedDataList[index] = updatedData;

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedDataList] };
    }),
  deleteData: (id) =>
    set((state) => {
      const index = state.dataList.findIndex((item) => item.id === id);

      if (index === -1) {
        // Si aucune adresse avec cet ID n'est trouvée, on renvoie l'état inchangé
        return state;
      }

      // Cloner l'objet trouvé pour ne pas muter l'état directement
      const updatedData = state.dataList.filter((item) => item?.id !== id);

      // Retourner le nouvel état avec la liste d'adresses mise à jour
      return { dataList: [...updatedData] };
    }),
  addData: (newData) =>
    set((state) => ({
      dataList: [...state.dataList, { ...newData }],
    })),
  setDataList: (newData) =>
    set((state) => ({
      dataList: [...newData],
    })),
  filterListe: (filterBy, filterOrder) =>
    set((state) => {
      let newListe = trierTableau(state.dataList, filterBy, filterOrder);

      return { dataList: [...newListe] };
    }),
  setDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...newData },
    })),
  modifyDataListConfig: (newData) =>
    set((state) => ({
      dataListConfig: { ...state.dataListConfig, ...newData },
    })),

  fetchList: async (perPage, page, role) => {
    try {
      set((state) => ({
        isLoading: true,
      }));
      if (role === "client") {
        clientService
          .getsSonde({
            per_page: perPage,
            page: page,
          })
          .then(
            (data) => {
              const response = data.data.data.data;

              let table = [];
              response.forEach((element) => {
                table.push({
                  value: element.id,
                  label: element.libelle,
                });
              });

              set((state) => ({
                isLoading: false,
                selectorListData: table,
                dataList: [...response],
              }));
            },
            (error) => {
              set((state) => ({
                isLoading: false,
              }));
              console.log(error);
            }
          );
      } else {
        adminPackageService
          .getsSonde({
            per_page: perPage,
            page: page,
          })
          .then(
            (data) => {
              const response = data.data.data.data;

              let table = [];
              response.forEach((element) => {
                table.push({
                  value: element.id,
                  label: element.libelle,
                });
              });

              set((state) => ({
                isLoading: false,
                selectorListData: table,
                dataList: [...response],
              }));
            },
            (error) => {
              set((state) => ({
                isLoading: false,
              }));
              console.log(error);
            }
          );
      }
    } catch (error) {
      set((state) => ({
        isLoading: false,
      }));
      console.error("Erreur lors de la récupération des détails :", error);
    }
  },
  resetData: () =>
    set((state) => {
      return { dataList: [] };
    }),
}));
