import React, { useCallback, useEffect, useState } from 'react'
import useDisclosure from '../../../hooks/useDisclosure';
//import Form from './Form';
import useAdminLists from '../../../hooks/useAdminLists';
import { formatDefaultDate } from '../../../utils/moment';
import { TableLoader } from '../../Utils/Loader';
import SalarieSalarieComponent from '../../Utils/Selectors/SalarieSalarieComponent';
import useFormValues, { useBasicFormValues } from '../../../hooks/useFormValues';
import adminService from '../../../services/admin/adminService';
import { exnetSpaceColor } from '../../../utils/Functions/otherFunctions';
import { useParams } from "react-router-dom";
import PaginationComponent from '../../paginationComponent/PaginationComponent';
import useTableFilter from '../../../hooks/useTableFilter';
import { TbArrowsUpDown } from 'react-icons/tb';
import UsersSelector from '../../Utils/Selectors/UsersSelector';
import { TableComponent } from '../../TableauComponent';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useAppContext } from '../../../context';
import clsx from 'clsx';

export default function HistoriesView() {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [showFilter, setShowFilter] = useState(true)
    const { isAuth, setIsAuth } = useAppContext();

    const {
        isOpen: isDeleteConfirmOpen,
        onClose: onDeleteConfirmClose,
        onOpen: onDeleteConfirmOpen
    } = useDisclosure();

    const { milieu: exnetSpace } = useParams()


    const [selectedItem, setSelectedItem] = useState(null)
    const [items, setItems] = useState({
        data: []
    })
    const [isLoading, setisLoading] = useState(false)

    const [search, setSearch] = useState("")

    const initialValue = {
        user_id: "",
        page: 1,
        ref: "",
        perPage: 25,
        startDate: "",
        endDate: ""
    }

    const { data: metadata, setFormValue, setData: setMetadata } = useBasicFormValues(initialValue)

    const onItemSelected = (item, action) => {
        setSelectedItem(item)
        if (action === "edit") {
            onOpen()
        } else {
            onDeleteConfirmOpen()
        }
    }

    const filteredLists = items.data/* .filter(item => {
        //const today = new Date().getTime()
        return item.user?.name.includes(search)
            && item.shipping?.ref?.toString().includes(metadata.ref)
            && item.user?.id?.toString().includes(metadata.user_id)
        //&& (metadata.start ? new Date(item.created_at).getTime() >= new Date(metadata.start).getTime() : true)
        //&& (metadata.end ? new Date(item.created_at).getTime() <= new Date(metadata.end).getTime() : true)
    }
    ) */

    const filterHistories = useCallback(

        async () => {

            try {

                const params = {
                    type: "all",
                    per_page: metadata.perPage,
                    page: metadata.page,
                    date: {}
                }

                if (metadata.user_id) {
                    params["user_id"] = metadata.user_id;
                }

                if (metadata.ref) {
                    const ref = parseInt(metadata.ref)

                    if (!isNaN(ref)) {
                        params["ref"] = ref;
                    }
                }

                if (metadata.startDate) {
                    /* if (!params.date) {
                        params.date = {}
                    } */
                    params.date.start = metadata.startDate
                }

                if (metadata.endDate) {
                    /* if (!params.date) {
                        params.date = {}
                    } */
                    params.date.end = metadata.endDate
                }

                const { data } = await adminService.filterHistories(params);

                if (data.success) {
                    setItems(_items => ({
                        ..._items,
                        data: data.data.data,
                        total: data.data.total,
                        lastPage: data.data.last_page,
                    }))
                    // if (metadata.page < data.data.last_page) {
                    //     setFormValue("page", metadata.page + 1)

                    // }
                }
            } catch (error) {
                console.error(error)
            }
        }, [metadata.user_id, metadata.page, metadata.perPage, metadata.ref, metadata.startDate, metadata.endDate])


    useEffect(() => {
        filterHistories()
    }, [metadata.page, metadata.perPage])

    const cancelSearch = useCallback(() => {
        setMetadata(initialValue)
        setSearch("")
    }, [initialValue, setMetadata])

    //console.log({ metadata })

    return (
        <div className={"px-2 md:px-0"}>

            <h4 className="uppercase page-title">Le 24 Heures</h4>
            <div className='flex justify-end'>
                <div className="rounded-full cursor-pointer w-fit bg-admin" style={{
                    color: "#fff",

                }}>
                    <FunnelIcon onClick={() => setShowFilter(!showFilter)} className={"h-4 w-4 m-3 text-xl font-bold  "} />
                </div>
            </div>
            <section
                className={clsx(
                    "transition-all duration-700 ease-in-out",
                    { "max-h-[800px] opacity-100  p-3 ": showFilter, "max-h-0 opacity-0": !showFilter }
                )}>
                <div className="items-center grid-cols-4 gap-3 space-y-2 md:grid md:space-y-0">

                    {/* <div className=" w-md">
                        <label
                            className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="suivi"
                        >
                            Recherche
                        </label>
                        <input
                            className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                            type="search"
                            placeholder="Recherche"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div> */}


                    <div className="">
                        <label
                            className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="suivi"
                        >
                            Ref
                        </label>
                        <div className="">
                            <input
                                className="w-full px-4 py-2 mb-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
                                type="search"
                                placeholder="45729"
                                value={metadata.ref}
                                onChange={(e) => setFormValue("ref", e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="">
                        <label
                            className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="suivi"
                        >
                            Utilisateur
                        </label>
                        <div className="mb-3">
                            {/* <SalarieSalarieComponent showLabel={false}
                            selectedValue={metadata.user_id}
                            onChange={(v) => {
                                //console.log({ v })
                                if (v.value) {
                                    //console.log({ v: v.value })
                                    setFormValue("user_id", v.value.id)
                                }
                            }} /> */}
                            <UsersSelector
                                profil="user"
                                showLabel={false}
                                selectedValue={metadata.user_id}
                                onChange={(v) => {
                                    if (v?.id) {
                                        //console.log({ v: v.value })
                                        setFormValue("user_id", v.id)
                                    }
                                }}
                            />

                        </div>
                    </div>

                    <div className="">
                        <label
                            className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="startDate"
                        >
                            Date de début
                        </label>
                        <input
                            className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700  border ring-gray-300 focus:ring-gray-500 bg-white  border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                            id="startDate"
                            type="date"
                            placeholder=""
                            value={metadata.startDate}
                            onChange={(e) => setFormValue("startDate", e.target.value)}
                        />
                    </div>

                    <div className="">
                        <label
                            className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
                            htmlFor="endDate"
                        >
                            Date de fin
                        </label>
                        <input
                            className="block text-sm w-full h-[40px] px-4 py-2 mb-3 leading-tight text-gray-700  border ring-gray-300 focus:ring-gray-500 bg-white  border-gray-500 rounded-lg appearance-none focus:outline-none focus:bg-white"
                            id="endDate"
                            type="date"
                            placeholder=""
                            value={metadata.endDate}
                            onChange={(e) => setFormValue("endDate", e.target.value)}
                        />
                    </div>

                    <button
                        onClick={filterHistories}
                        type="button"
                        className=" text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center mr-4"
                        // disabled={isSubmitting}
                        style={{
                            color: "#fff",
                            backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                        }}
                    >
                        Rechercher
                    </button>

                    <button
                        onClick={cancelSearch}
                        type="button"
                        className=" text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-3 py-2.5 text-center"
                        // disabled={isSubmitting}
                        style={{
                            color: "#fff",
                            backgroundColor: `${exnetSpaceColor(exnetSpace)}`,
                        }}
                    >
                        Annuler
                    </button>


                </div>
            </section>
            <div className="py-3">
                {items.total ?
                    <PaginationComponent
                        page={metadata.page}
                        handlePageClick={(v) => {
                            setMetadata(m => ({ ...m, page: v }))
                        }}
                        totalItems={items.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={(v) => {
                            setMetadata(m => ({ ...m, perPage: v }))
                        }}
                    /> : null}
            </div>
            <Lists
                data={filteredLists}
                onSelect={onItemSelected}
                isLoading={isLoading}
                metadata={metadata}
            />
            <div className="py-3">
                {items.total ?
                    <PaginationComponent
                        page={metadata.page}
                        element={'pagination'}
                        handlePageClick={(v) => {
                            setMetadata(m => ({ ...m, page: v }))
                        }}
                        totalItems={items.total}
                        perPageItems={metadata.perPage}
                        setnumberPerPage={(v) => {
                            setMetadata(m => ({ ...m, perPage: v }))
                        }}
                    /> : null}
            </div>
        </div>
    )
}


const Lists = ({ data, metadata, isLoading }) => {
    const { results, onTableHeaderClick } = useTableFilter({ items: data });

    const HEADERS = [
        { label: 'Date', filterKey: 'created_at' },
        { label: 'Expédition', filterKey: 'shipping.ref' },
        { label: 'Utilisateur', filterKey: 'user.name' },
        { label: 'Action', filterKey: null },
    ];

    return (
        <TableComponent withAction={false} headerList={HEADERS} dataLength={data.length} isLoading={isLoading}>
            {data.map((item, i) => {
                return (
                    <tr key={i} className="py-3 divide-y divide-gray-300">
                        <td>{formatDefaultDate(item.created_at, "DD-MM-YYYY à hh:mm")}</td>
                        <td>
                            {item?.shipping?.ref || '-'}
                        </td>
                        <td>
                            {item?.user?.name}
                        </td>
                        <td>
                            {item.action}
                        </td>
                    </tr>
                )
            })
            }
        </TableComponent>


    )
}