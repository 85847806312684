import React, { useState } from "react";
import { useEffect } from "react";
import {
  EmballageDataStore,
  TypeDiagnoBagDataStore,
  TypeEmballageDataStore,
  TypeSondeDataStore,
} from "../../states/tableData";
import { Selector } from "../Utils/Selectors/Selector";
import { ExpeditionStore } from "../../store/expedition";
import { useAppContext } from "../../context";

export const EmballageTypeSelector = ({
  label,
  modifyData,
  errorInformation,
  value,
}) => {
  const selectorListData = TypeEmballageDataStore(
    (state) => state.selectorListData
  );
  const fetchList = TypeEmballageDataStore((state) => state.fetchList);
  const dataListConfig = TypeEmballageDataStore(
    (state) => state.dataListConfig
  );
  const isLoading = TypeEmballageDataStore((state) => state.isLoading);
  const { isAuth, setIsAuth } = useAppContext();

  useEffect(() => {
    fetchList(dataListConfig.perPageItems, dataListConfig.page, isAuth?.userRole);
  }, []);


  return (
    <div>
      <Selector
        isLoading={isLoading}
        onSelect={(valeur) => {
          modifyData(valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={label.toUpperCase()}
        value={value}
        label={label}
        withLabel={true}
        isError={errorInformation.field === "mesure_type"}
        data={selectorListData}
      />
    </div>
  );
};

export const CapacitySelector = ({
  label,
  modifyData,
  errorInformation,
  value,
  emballageType,
  isMulti = false,
}) => {
  const selectorListData = EmballageDataStore(
    (state) => state.selectorListData
  );
  const fetchList = EmballageDataStore((state) => state.fetchList);
  const fetchListByTypeId = EmballageDataStore(
    (state) => state.fetchListByTypeId
  );
  const dataListConfig = EmballageDataStore((state) => state.dataListConfig);
  const isLoading = EmballageDataStore((state) => state.isLoading);
   const { isAuth, setIsAuth } = useAppContext();

  useEffect(() => {
    if (emballageType) {
      
      fetchListByTypeId(
        dataListConfig.perPageItems,
        dataListConfig.page,
        emballageType,
        isAuth?.userRole
      );
    } else {
      fetchList(dataListConfig.perPageItems, dataListConfig.page, isAuth?.userRole);
    }
  }, [emballageType]);

  return (
    <div>
      <Selector
        isLoading={isLoading}
        onSelect={(valeur) => {
          modifyData(valeur);
        }}
        isMulti={isMulti}
        errorMessage={errorInformation.message}
        placeholder={label.toUpperCase()}
        value={value}
        label={label}
        withLabel={true}
        isError={errorInformation.field === "mesure_type"}
        data={selectorListData}
      />
    </div>
  );
};

export const SondeSelector = ({
  label,
  modifyData,
  errorInformation,
  value,
  emballageType,
  isMulti = false,
}) => {
  const selectorListData = TypeSondeDataStore(
    (state) => state.selectorListData
  );
  const fetchList = TypeSondeDataStore((state) => state.fetchList);
  const fetchListByTypeId = TypeSondeDataStore(
    (state) => state.fetchListByTypeId
  );
  const dataListConfig = TypeSondeDataStore((state) => state.dataListConfig);
  const isLoading = TypeSondeDataStore((state) => state.isLoading);
   const { isAuth, setIsAuth } = useAppContext();

  useEffect(() => {
    fetchList(dataListConfig.perPageItems, dataListConfig.page,isAuth?.userRole);
  }, []);

  return (
    <div>
      <Selector
        isLoading={isLoading}
        onSelect={(valeur) => {
          modifyData(valeur);
        }}
        isMulti={isMulti}
        errorMessage={errorInformation.message}
        placeholder={label.toUpperCase()}
        value={value}
        label={label}
        withLabel={true}
        isError={errorInformation.field === "mesure_type"}
        data={selectorListData}
      />
    </div>
  );
};

export const DiagoBagSelector = ({
  label,
  modifyData,
  errorInformation,
  value,
  emballageType,
  isMulti = false,
}) => {
  const selectorListData = TypeDiagnoBagDataStore(
    (state) => state.selectorListData
  );
  const fetchList = TypeDiagnoBagDataStore((state) => state.fetchList);
  const fetchListByTypeId = TypeDiagnoBagDataStore(
    (state) => state.fetchListByTypeId
  );
  const dataListConfig = TypeDiagnoBagDataStore(
    (state) => state.dataListConfig
  );
  const isLoading = TypeDiagnoBagDataStore((state) => state.isLoading);
   const { isAuth, setIsAuth } = useAppContext();

  useEffect(() => {
    fetchList(dataListConfig.perPageItems, dataListConfig.page,isAuth?.userRole);
  }, []);

  return (
    <div>
      <Selector
        isLoading={isLoading}
        onSelect={(valeur) => {
          modifyData(valeur);
        }}
        isMulti={isMulti}
        errorMessage={errorInformation.message}
        placeholder={label.toUpperCase()}
        value={value}
        label={label}
        withLabel={true}
        isError={errorInformation.field === "mesure_type"}
        data={selectorListData}
      />
    </div>
  );
};
