import React from "react";

function HeaderComponent({title,subtitle}) {
    return (
        <div className="">
            <h4 className="mb-1 uppercase page-title">{title}</h4>
            <span className="text-sm text-gray-400">{subtitle}</span>
        </div>
    );
}

export default HeaderComponent;
