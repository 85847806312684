import React from 'react'
import { Liste } from './liste'
import { ButtonComponent } from '../../../components/ButtonComponent'
import ModalComponent2 from '../../../components/modal/ModalComponent'
import { FormCapacity } from './form'

export const AdminEmballageCapacityPage = () => {
  const [open, setOpen] = React.useState(false)
  const [title, setTitle] = React.useState('')

  return (
    <>
      <div>

        <div
          className={"flex  items-center justify-between mt-10"}
        >
          <h1>Dimensions des emballages</h1>

          <ButtonComponent
            onClick={() => setOpen(true)}
            title="Ajouter une nouvelle dimension"
          />

        </div>
        <Liste />
      </div>

      <ModalComponent2 title={"Nouvelle dimension"} open={open} setOpen={setOpen}>
        <FormCapacity  setOpen={setOpen}/>
      </ModalComponent2>
    </>

  )
}
