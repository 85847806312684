import React, { useState } from "react";
import Select from "react-select";
import clsx from "clsx";

export const Selector = ({
  onSelect,
  value,
  label,
  isError,
  errorMessage,
  data,
  message = "Valeur non trouvée",
  withLabel = true,
  isLoading = false,
  ...other
}) => {
  const selectedValue = other?.isMulti
    ? value
    : data?.find((option) => option.value === value);

  return (
    <div className="">
      {withLabel && (
        <label
          htmlFor={label}
          className="block mb-1 text-lg font-normal leading-6"
        >
          {label}
        </label>
      )}

      <Select
        id="AddressSelector"
        placeholder={label}
        options={data}
        value={selectedValue}
        onChange={(selected) => {
          if (other?.isMulti) {
            onSelect(selected);
          } else {
            onSelect(selected?.value);
          }
        }}
        isLoading={isLoading}
        className="text-base"
        classNamePrefix="select h bg-primary"
        noOptionsMessage={() => message}
        loadingMessage={() => "Chargement..."}
        {...other}
      />
      {isError && (
        <p className="mt-2 text-sm text-primary" id={label}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
