import React, { useEffect, useState } from "react";
import ModalComponent from "../Utils/Modal/Index";
import opsService from "../../services/ops/opsService";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import contactService from "../../services/contact/contactService";
import clsx from "clsx";
import { useAppContext } from "../../context";

export default function CarnetAddress({ isOpen, onClose, onSelect }) {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState({
    data: [],
    page: 1,
  });

  const { isAuth, setIsAuth } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const params = {
          type: isAuth?.spaceName,
          per_page: 20,
          page: 1,
          filter: {
            other: search,
          },
        };
        const { data } = await opsService.getContacts(params);
        if (data.success) {
          setContacts((c) => ({
            ...c,
            data: data.data.data,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [search]);

  const handleSelect = (c) => {
    onSelect?.(c);
    onClose();
    setSearch("");
  };

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      <div className="p-3 py-1">
        <label
          htmlFor="options"
          className="block mb-2 text-lg font-semibold text-gray-900 "
        >
          Carnet d'adresse
        </label>
        <input
          className="py-3 bg-white border border-gray-500 rounded-lg outline-none form-control"
          type="type"
          placeholder="Recherche"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div>
          {contacts.data.length > 0 ? (
            <div className="mt-2 mb-5 overflow-y-scroll bg-gray-100 max-h-96">
              {contacts.data.map((c, i) => (
                <div
                  className="p-2 m-1 bg-white cursor-pointer"
                  key={i}
                  onClick={() => {
                    handleSelect(c);
                  }}
                >
                  <div className="text-[14px]">{c.fullname}</div>
                </div>
              ))}
            </div>
          ) : isLoading ? null : (
            <div className="mt-3 text-base font-semibold text-center">
              Aucun contact trouvé :(
            </div>
          )}
        </div>
      </div>
    </ModalComponent>
  );
}

export const InputNomSearch = ({
  label,
  attribute,
  value,
  onChange,
  onSelect,
  isDisabled,
  client,
  isError = false,
  errorMessage = "",
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState({
    data: [],
    page: 1,
  });

  const [isFocus, setIsFocus] = useState(false);

  const { isAuth, setIsAuth } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const params = {
          type: isAuth?.spaceName,
          per_page: 20,
          page: 1,
          filter: {
            other: value,
          },
        };
        let options = {};

        if (client) {
          options = {
            user_id: isAuth.id,
            by: "user",
            contact_type: "all",
            type: "all",
            page: 1,
            filter: {
              other: value,
            },
          };
        }

        const { data } = client
          ? await contactService.fechClientConatctList(options)
          : await opsService.getContacts(params);
        if (data.success) {
          // setContacts(c => ({
          //     ...c,
          //     data: data.data.data//.map(d => ({label: d.fullname, value: d.id}))
          // }))
          setContacts({ data: data.data.data });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [value]);

  const options = contacts.data;

  //console.log({ [attribute]: value })

  return (
    <div className="relative">
      {label && (
        <label className="block mb-2 text-sm font-medium text-gray-900 ">
          {label}
        </label>
      )}

      <input
        // className={`bg-white border border-gray-500 rounded-lg outline-none form-control`}
        className={clsx(
          {
            "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500":
              isError,
            "ring-gray-300 focus:ring-gray-500 bg-white border border-gray-500":
              !isError,
          },
          "px-2 block w-full rounded-md border-0 py-1.5 pr-10  ring-1 ring-inset  focus:ring-2 focus:ring-inset text-base sm:leading-6"
        )}
        type="type"
        placeholder="Recherche"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setIsFocus(false);
          }, 1000);
        }}
        disabled={isDisabled}
        {...props}
      />
      {isError && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}

      {isFocus && options.length > +0 && (
        <div className="absolute left-0 right-0 z-20 py-2 mt-2 overflow-scroll bg-white rounded-sm shadow max-h-60">
          {options.map((contact, i) => (
            <div
              onClick={() => {
                onSelect(contact);
              }}
              className="px-4 py-2 text-sm cursor-pointer"
              key={i}
            >
              {contact[attribute]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
