import React from 'react'
import LoaderTableau from '../../tableauComponent/LoaderTableau'
import { DetailTabsComponent } from './DetailTabsComponent'
import { Manifest } from './Manifest'
import AddTrackingComponent from '../../tracking/AddTrackingComponent'
import { PodTabsComponent } from './PodTabsComponent'
import { CommentaireTabsComponent } from './CommentaireTabsComponent'
import { FactureTabsComponent } from './FactureTabsComponent'

import { ShippingDetails } from '../../../store/shippingDetails'
import useAuthorization from '../../../hooks/useAuthorization'
import { FichiersTabsComponent } from './FichiersTabsComponent'
import { EmailTabsComponent } from './EmailTabsComponent'

export const OpsShippingDetails = ({ activeTabs }) => {

    const expeditionData = ShippingDetails((state) => state.details);

    const { isShippingTabAllowed } = useAuthorization()

    const setExpeditionData = (ss) => {
        console.log('ddee')
    }

    return (
        <div>
            {(expeditionData.id) ? (
                <div className="tab-content twitter-bs-wizard-tab-content">
                    {expeditionData?.raison && expeditionData?.raison !== "" && <>
                        <div id="info-alert" class="flex items-center p-4 mb-4 bg-blue-100 border border-blue-300 rounded-lg shadow-sm" role="alert">
                            <svg aria-hidden="true" class="w-6 h-6 text-blue-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M18 10A8 8 0 11.95 4.636l-.072-.003a7.967 7.967 0 011.52-1.52A8 8 0 0118 10zM9 4a1 1 0 012 0v4a1 1 0 01-2 0V4zm.25 8.92a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z" clip-rule="evenodd"></path>
                            </svg>
                            <div class="ml-3 text-sm font-medium text-blue-800">
                                Raison de l'annulation:  {expeditionData?.raison}
                            </div>
                            <button
                                type="button"
                                class="ml-auto -mx-1.5 -my-1.5 bg-blue-100 text-blue-800 rounded-lg p-1.5 hover:bg-blue-200 focus:ring-2 focus:ring-blue-400 focus:outline-none"
                                data-dismiss-target="#info-alert"
                                aria-label="Fermer"
                                onclick="document.getElementById('info-alert').remove();"
                            >
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </div>

                    </>}
                    {activeTabs === "tabsDetail" &&
                        <div className="tab-pane active" id="tabsDetail">
                            {isShippingTabAllowed.details &&
                                <DetailTabsComponent />}
                        </div>
                    }

                    {activeTabs === "tabsFichiers" && <>
                        {isShippingTabAllowed.fichiers && <div className="tab-pane" id="tabsFichiers">
                            <FichiersTabsComponent expedition={expeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsEmail" && <>
                        {isShippingTabAllowed.mails && <div className="tab-pane" id="tabsEmail">
                            <EmailTabsComponent expedition={expeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsManifest" && <>
                        {isShippingTabAllowed.manifest && <div className="tab-pane" id="tabsManifest">
                            <Manifest expedition={expeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsTracking" && <>
                        {isShippingTabAllowed.tracking && <div className="tab-pane" id="tabsTracking">
                            <AddTrackingComponent expedition={expeditionData} setExpeditionData={setExpeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsPod" && <>
                        {isShippingTabAllowed.pod && <div className="tab-pane" id="tabsPod">
                            <PodTabsComponent shipping_id={expeditionData.id} />
                        </div>}
                    </>}

                    {activeTabs === "tabsCommentaire" && <>
                        {isShippingTabAllowed.comments && <div className="tab-pane" id="tabsCommentaire">
                            <CommentaireTabsComponent expedition={expeditionData} />
                        </div>}
                    </>}

                    {activeTabs === "tabsFacturation" && <>
                        {isShippingTabAllowed.facturations && <div className="tab-pane" id="tabsFacturation">
                            <FactureTabsComponent />
                        </div>}
                    </>}

                </div>
            ) : (
                <LoaderTableau />
            )}
        </div >
    )
}
