import React, {useState } from "react";
import { Link,} from "react-router-dom";
import {
  exnetSpaceColor,
  expeditionTypeByCountries,
  refPrefixer,
  returnExpeditionTypeLogo,
  splitText,
} from "../../../utils/Functions/otherFunctions";
import { toast } from "react-toastify";
import ExpeditionService from "../../../services/expedition/ExpeditionService";
import useDisclosure from "../../../hooks/useDisclosure";

import { useAppContext } from "../../../context";
import { TableComponent } from "../../TableauComponent";
import { TbMotorbike, TbTruckDelivery } from "react-icons/tb";
import moment from "moment";
import { getCountryNameOrCode } from "../../../utils/const/countriesConstant";
import { SwitchComponent } from "../../Utils/Index";
import ModalUI from "../../Utils/Modal/Modal";
import { TableDataStore } from "../../../states/tableData";

const headers = [
  { label: "REF", filterKey: "ref" },
  { label: "ref inter", filterKey: "customer_ref" },
  { label: "EXP", filterKey: "expeditor.fullname" },
  { label: "adresse", filterKey: "expeditor.address1" },
  { label: "DEST", filterKey: "receiver.fullname" },
  { label: "adresse", filterKey: "receiver.address1" },
  { label: "emballage", filterKey: "receiver.address1" },
  { label: "Diagobag", filterKey: "suivis_details" },
  { label: "Sonde", filterKey: false },
  { label: "Statut", filterKey: false },
  { label: "Centre de Fact.", filterKey: "billing_center.billing_center_name" },
];
export const EmballageShippingList = ({ type, isLoading }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuth, setIsAuth } = useAppContext();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const items = TableDataStore((state) => state.dataList);
  const update = TableDataStore((state) => state.updateData);
  const deleteData = TableDataStore((state) => state.deleteData);
  
  const updateStatus = async () => {
    setUpdateLoading(true);
    const value = items.find((item) => item.id === selectedId)
      ?.shipping_emballage?.[0];
    ExpeditionService.prepareEmballageShipping({
      shipping_id: selectedId,
      is_prepared: value?.is_prepared=== 1 ? 0 : 1,
    }).then(
      (res) => {
        if (res.status === 200) {
          deleteData(selectedId);
          // update(selectedId, { shipping_emballage: [ {
          //   ...value,
          //   is_prepared: value?.is_prepared === 1 ? 0 : 1
          //  }
          // ] });
          setUpdateLoading(false);
          toast.success("Mise à jour effectuée avec succès");
          onClose();
          setSelectedId("");
        }
      },
      (error) => {
        setUpdateLoading(false);
        toast.error("Une erreur est survenue");
        onClose();
        setSelectedId("");
      }
    );

    onClose();
  };

  const handleOpen = (id) => {
    setSelectedId(id);
    onOpen();
  };

  return (
    <div>
      <TableComponent
        checkboxVisible={false}
        allowCheckBoxToggler={false}
        withAction={false}
        headerList={headers}
        dataLength={items.length}
        isLoading={isLoading}
      >
        {items.map((item, index) => (
          <tr
            key={item?.ref}
            className={` even:bg-gray-200 cursor-pointer text-uppercase tableRow `}
          >
            <td
            // onClick={() => {
            //   localStorage.setItem("expeditions", JSON.stringify(items));
            //   navigate(redirectionUrl);
            //   window.open(redirectionUrl, '_blank');

            // }}
            >
              <Link
                to={`/${isAuth?.userRole}/${isAuth?.spaceName}/dash/detail/${item.ref}`}
                className={"text-sm font-normal"}
                style={{ color: exnetSpaceColor(item.type) }}
              >
                {refPrefixer(item.type)}
                {item.ref} <br />
                <div
                  style={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.type_expedition === "course" ||
                  item.type_expedition === "courses" ? (
                    <>
                      {item.type_de_roue === "quatre_roues" ||
                      item.type_de_roue === "4" ? (
                        <>
                          <TbTruckDelivery
                            color={exnetSpaceColor(item.type)}
                            size={25}
                          />
                        </>
                      ) : (
                        <>
                          <TbMotorbike
                            color={exnetSpaceColor(item.type)}
                            size={25}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {expeditionTypeByCountries(
                        item?.expeditor?.country,
                        item?.receiver?.country
                      ) === "export" ? (
                        <img
                          src={returnExpeditionTypeLogo("export", item.type)}
                          className={"mt-2 w-10"}
                          alt=""
                        />
                      ) : expeditionTypeByCountries(
                          item?.expeditor?.country,
                          item?.receiver?.country
                        ) === "import" ? (
                        <img
                          src={returnExpeditionTypeLogo("import", item.type)}
                          className={"mt-2 w-10"}
                          alt=""
                        />
                      ) : (
                        <img
                          src={returnExpeditionTypeLogo("export", item.type)}
                          className={"mt-2 w-10"}
                          alt=""
                        />
                      )}
                    </>
                  )}
                </div>
              </Link>
            </td>

            <td
              className={"text-center"}
              style={{
                maxWidth: "50px",
              }}
              onClick={() => {
                localStorage.setItem("expeditions", JSON.stringify(items));
              }}
            >
              <Link
                to={`/${isAuth?.userRole}/${isAuth?.spaceName}/dash/detail/${item.ref}`}
                className={"text-sm font-normal"}
                style={{ color: exnetSpaceColor(item.type) }}
              >
                {item.customer_ref ? item.customer_ref : "-"}
              </Link>
            </td>

            <td
              style={{
                maxWidth: "200px",
              }}
              onClick={() => {
                localStorage.setItem("expeditions", JSON.stringify(items));
              }}
            >
              <Link
                to={`/${isAuth?.userRole}/${isAuth?.spaceName}/dash/detail/${item.ref}`}
                className={"text-black"}
              >
                {" "}
                <span className={" font-bold"}>
                  {splitText(item.expeditor.fullname)}
                </span>
                <br />
                <span>{splitText(item.expeditor.company)}</span>
                <br />
                <i className="font-bold ">
                  {" "}
                  {moment(item.pickup_date).format("DD-MM-YYYY")}
                </i>
                <br />
                <i className="font-bold "> {item.pickup_time}</i>
                <br />
              </Link>
            </td>
            <td
              onClick={() => {
                localStorage.setItem("expeditions", JSON.stringify(items));
                // navigate(redirectionUrl);
              }}
            >
              <Link
                to={`/${isAuth?.userRole}/${isAuth?.spaceName}/dash/detail/${item.ref}`}
                className={"text-black text-sm font-normal"}
              >
                <span> {splitText(item.expeditor.address1)}</span> <br />
                <span>{item.expeditor.city}</span>
                <br />
                <span>{item.expeditor.postalCode}</span>
                <div className={"flex items-center gap-3 flex-wrap"}>
                  <span>
                    <img
                      src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                        getCountryNameOrCode(item?.expeditor?.country),
                        true
                      )}.png`}
                      srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                        getCountryNameOrCode(item?.expeditor?.country),
                        true
                      )}.png 2x,
              https://flagcdn.com/48x36/${getCountryNameOrCode(
                getCountryNameOrCode(item?.expeditor?.country),
                true
              )}.png 3x`}
                      width="16"
                      height="12"
                      alt={getCountryNameOrCode(item?.expeditor?.country)}
                    />
                  </span>
                  <span> {getCountryNameOrCode(item?.expeditor?.country)}</span>
                </div>
              </Link>
            </td>

            <td
              style={{
                backgroundColor: item.color ? item.color : "",
                maxWidth: "200px",
              }}
              onClick={() => {
                localStorage.setItem("expeditions", JSON.stringify(items));
                // navigate(redirectionUrl);
              }}
            >
              <Link
                to={`/${isAuth?.userRole}/${isAuth?.spaceName}/dash/emballage/${item.ref}`}
                className={"text-black"}
              >
                {" "}
                <span className={"font-bold"}>
                  {splitText(item.receiver.fullname)}
                </span>
                <br />
                <span>{item.receiver.company}</span> <br />
                <i className="font-bold ">
                  {moment(item.delivery_date).format("DD-MM-YYYY")}{" "}
                </i>
                <br />
                <i className="font-bold "> {item.delivery_time}</i>
              </Link>
            </td>
            <td
              onClick={() => {
                localStorage.setItem("expeditions", JSON.stringify(items));
                // navigate(redirectionUrl);
              }}
            >
              <Link
                to={`/${isAuth?.userRole}/${isAuth?.spaceName}/dash/detail/${item.ref}`}
                className={"text-black text-sm font-normal"}
              >
                <span> {splitText(item.receiver.address1)}</span> <br />
                <span>{item.receiver.city}</span>
                <br />
                <span>{item.receiver.postalCode}</span>
                <div className={"flex items-center gap-3 flex-wrap"}>
                  <span>
                    <img
                      src={`https://flagcdn.com/16x12/${getCountryNameOrCode(
                        getCountryNameOrCode(item?.receiver?.country),
                        true
                      )}.png`}
                      srcSet={`https://flagcdn.com/32x24/${getCountryNameOrCode(
                        getCountryNameOrCode(item?.receiver?.country),
                        true
                      )}.png 2x,
              https://flagcdn.com/48x36/${getCountryNameOrCode(
                getCountryNameOrCode(item?.receiver?.country),
                true
              )}.png 3x`}
                      width="16"
                      height="12"
                      alt={getCountryNameOrCode(item?.receiver?.country)}
                    />
                  </span>
                  <span> {getCountryNameOrCode(item?.receiver?.country)}</span>
                </div>
              </Link>
            </td>
            <td>
              {item?.shipping_emballage?.[0]?.emballage?.nom_produit || "-"}{" "}
            </td>

            <td>
              {item?.shipping_emballage?.[0]?.type_diagnobag?.libelle || "-"}
            </td>
            <td>{item?.shipping_emballage?.[0]?.type_sonde?.libelle || "-"}</td>
            <td>
              <SwitchComponent
                id="import"
                label="import"
                checked={item?.shipping_emballage?.[0]?.is_prepared === 1}
                onChange={(checked) => {
                  handleOpen(item?.id);
                }}
              />
            </td>
            <td className="max-w-[100px]">
              <div>{item.billing_center?.billing_center_name}</div>
            </td>
          </tr>
        ))}
      </TableComponent>

      <ModalUI
        isOpen={isOpen}
        onClose={onClose}
        title={"Etes de vouloir changer le statut de l'emballage ?"}
      >
        <div className="px-4 py-2 my-5">
          <div className="mt-3">
            <div className="flex justify-center gap-4 mt-2">
              <button
                onClick={updateStatus}
                type="button"
                disabled={updateLoading}
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                {updateLoading ? "Modification..." : "Oui, j'en suis sûr"}
              </button>
              <button
                onClick={onClose}
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10     "
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </ModalUI>
    </div>
  );
};
