import React, { useEffect, useState } from "react";
import { NouvelTableau } from "../../../components/NouvelTableau";
import { TypeDiagnoBagDataStore } from "../../../states/tableData";
import { formatDateForInput } from "../../../utils/Functions/functions";
import { PencilIcon } from "@heroicons/react/24/outline";
import ModalComponent2 from "../../../components/modal/ModalComponent";
import { TypeEmballageForm } from "./form";
import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { useAppContext } from "../../../context";
import { toast } from "react-toastify";
import { adminPackageService } from "../../../services/admin/packageService";

export const Liste = () => {
  const dataList = TypeDiagnoBagDataStore((state) => state.dataList);
  const fetchList = TypeDiagnoBagDataStore((state) => state.fetchList);
  const dataListConfig = TypeDiagnoBagDataStore(
    (state) => state.dataListConfig
  );
  const modifyDataListConfig = TypeDiagnoBagDataStore(
    (state) => state.modifyDataListConfig
  );
  const updateData = TypeDiagnoBagDataStore((state) => state.updateData);
  const setDataContent = TypeDiagnoBagDataStore(
    (state) => state.setDataContent
  );
  const chargement = TypeDiagnoBagDataStore((state) => state.isLoading);
  const [emballageId, setEmballageId] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchList(dataListConfig.perPageItems, dataListConfig.page);
  }, []);

  const handleOpenModal = (id) => {
    setEmballageId(id);
    setOpen(true);
  };

  const handleSwitch = (id) => {
    try {
      setIsLoading(true);
      const toastsId = toast.loading("Modification en cours ...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      const value = !dataList.find((item) => item.id === id).active;

      adminPackageService
        .changeDiagnobagState(id, value == 1 ? "activate" : "deactivate")
        .then(
          (response) => {
            toast.update(toastsId, {
              render: "Modification effectuée avec succès",
              type: "success",
              isLoading: false,
              autoClose: 1000,
              position: "top-center",
            });
            updateData(id, { active: value });
            setDataContent({});
            setIsLoading(false);
            setOpen(false);
          },
          (error) => {
            toast.update(toastsId, {
              render: "Erreur lors de la modification",
              type: "error",
              isLoading: false,
              autoClose: 1000,
              position: "top-center",
            });
            console.log(error);
            setIsLoading(false);
          }
        );
    } catch (error) {
      toast.error("Une erreur est survenue lors de la modification");
    }
  };

  return (
    <>
      <div>
        <NouvelTableau
          headerList={headerList}
          isLoading={chargement}
          dataLength={dataList?.length}
          withAction={true}
          checkboxVisible={false}
          TableDataStore={TypeDiagnoBagDataStore}
        >
          {dataList.map((item, index) => (
            <tr
              key={index}
              className="cursor-pointer text-uppercase tableRow tex"
            >
              <td className={"text-left"}>
                <div className="pl-3">{item.libelle}</div>
              </td>

              <td className={"text-left"}>
                <div className="pl-3">{item.longueur}</div>
              </td>
              <td className={"text-left"}>
                <div className="pl-3">{item.largeur}</div>
              </td>
              <td>
                <SwitchComponent
                  value={item.active == 1 ? true : false}
                  onChange={() => handleSwitch(item.id)}
                />
              </td>
              <td>{formatDateForInput(item?.created_at)}</td>
              <td className="text-center ">
                <div className="flex items-center justify-center">
                  <PencilIcon
                    onClick={() => handleOpenModal(item.id)}
                    className="w-4 h-4"
                  />
                </div>
              </td>
            </tr>
          ))}
        </NouvelTableau>
      </div>

      <ModalComponent2 title={"Mise à jour"} open={open} setOpen={setOpen}>
        <TypeEmballageForm setOpen={setOpen} action={emballageId} />
      </ModalComponent2>
    </>
  );
};

const headerList = [
  {
    label: "Title",
    filterKey: "libelle",
  },
  {
    label: "Longueur",
    filterKey: "longueur",
  },
  {
    label: "Largeur",
    filterKey: "largeur",
  },
  {
    label: "Active",
    filterKey: "active",
  },
  {
    label: "Date",
    filterKey: "created_at",
  },
];

const SwitchComponent = ({ value, onChange }) => {
  const { isAuth, setIsAuth } = useAppContext();

  return (
    <div className="flex items-center justify-between my-2">
      {/* <span className='text-lg font-bold' >Souhaitez-vous une founiture d'emballage</span> */}
      <Switch
        checked={value}
        onChange={onChange}
        className={clsx(
          {
            "bg-paris": isAuth?.spaceName === "paris" && value,
            "bg-medical ": isAuth?.spaceName === "medical" && value,
            "bg-service ": isAuth?.spaceName === "service" && value,
            "bg-admin ": isAuth?.spaceName === "admin" && value,
            "bg-blue-500": !value,
          },
          "group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out",

          value ? "bg-blue-500" : "bg-red-900",
          "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-"
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          className={clsx(
            "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            value ? "translate-x-5" : "translate-x-0"
          )}
        >
          <span
            aria-hidden="true"
            className={clsx(
              "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in",
              value ? "opacity-0 duration-100 ease-out" : "opacity-100"
            )}
          >
            <svg
              fill="none"
              viewBox="0 0 12 12"
              className={clsx(
                {
                  "text-paris": isAuth?.spaceName === "paris",
                  "text-medical": isAuth?.spaceName === "medical",
                  "text-service": isAuth?.spaceName === "service",
                  "text-admin": isAuth?.spaceName === "admin",
                },
                "w-3 h-3"
              )}
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            aria-hidden="true"
            className={clsx(
              "absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out",
              value ? "opacity-100 duration-200 ease-in" : "opacity-0"
            )}
          >
            <svg
              fill="currentColor"
              viewBox="0 0 12 12"
              className={clsx(
                {
                  "text-paris": isAuth?.spaceName === "paris",
                  "text-medical": isAuth?.spaceName === "medical",
                  "text-service": isAuth?.spaceName === "service",
                  "text-admin": isAuth?.spaceName === "admin",
                },
                "w-3 h-3"
              )}
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </div>
  );
};
