import Joi from "joi";
import { countriesEU } from "../const/countriesConstant";
import { isValidPhoneNumber } from "libphonenumber-js";

const customMessages = {
  "string.base": "{{#label}} doit être une chaîne de caractères",
  "string.uri": "{{#label}} doit être une URL valide",
  "string.empty": "{#label} ne doit pas être vide",
  "array.min": "{#label} doit comporter au minimum {{#limit}} elément(s)",
  "array.max": "{#label} doit comporter au plus {{#limit}} elément(s)",
  "array.length": "{#label} doit comporter au minimum {{#limit}} elément(s)",
  "string.email": "Veuillez fournir une adresse email valide pour {{#label}}",
  "string.required": "{{#label}} est obligatoire",
  "string.length":
    "{{#label}} doit contenir contient {{#limit}} de caractère(s)",
  "string.min": "{{#label}} doit comporter au minimum {{#limit}} caractère(s)",
  "string.max": "{{#label}} doit comporter au plus {{#limit}} caractère(s)",
  "any.required": "{{#label}} est obligatoire",
  "string.isoDate": "{{#label}} doit être une date valide au format ISO",
  "string.valid": "{{#label}} n'a pas une valeur valide",
  "boolean.base": "{{#label}} doit être un boolean",
  "number.base": "{{#label}} doit être un nombre",
  "object.base": "{{#label}} doit être un objet",
  "string.pattern.base": "{{#label}} est invalide",
  "string.pattern.name":
    "La chaîne doit contenir au moins 3 caractères, y compris des chiffres.",
  "string.pattern.invert.base": "{{#label}} est invalide",
  "string.pattern.invert.name": "{{#label}} est invalide",
  "string.trim": "{{#label}} est invalide",
};

const returnError = (response) => {
  const { error } = response;
  if (error) {
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    console.log(error.details[0]);
    let detail = error.details[0];
    return {
      valid: false,
      error: true,
      errorData: {
        field: fieldName,
        message: errorMessage,
        position: detail?.context?.key,
      },
    };
  }
  return { valid: true };
};

export const VerifyStep_1 = (body) => {
  const schema = Joi.object({
    bill_center_id: Joi.object({
      value: Joi.alternatives()
        .try(
          Joi.number().required().messages({
            "string.empty":
              "Le champ centre de facturation ne doit pas être vide",
            "any.required": "Le champ centre de facturation est obligatoire.",
            "number.base":
              "Le champ centre de facturation doit être un nombre.",
          }),
          Joi.string().allow("").messages({
            "string.empty":
              "Le champ centre de facturation ne doit pas être vide",
          })
        )
        .required(),
      label: Joi.string().required().messages({
        "any.required": "Le champ centre de facturation est obligatoire.",
        "string.base":
          "Le champ centre de facturation doit être une chaîne de caractères.",
      }),
    })
      .required()
      .messages({
        "any.required": "Le champ centre de facturation est obligatoire.",
        "string.base":
          "Le champ centre de facturation doit être une chaîne de caractères.",
      }),
    customer_ref: Joi.any().optional(),
    tracking_mail: Joi.any().optional(),
    username: Joi.string().required().messages({
      "any.required": "Le champ nom d'utilisateur est obligatoire.",
      "string.base":
        "Le champ nom d'utilisateur doit être une chaîne de caractères.",
      "string.empty": "Le champ nom d'utilisateur ne doit pas être vide",
    }),
    user_id: Joi.any().optional(),
    nomContact: Joi.any().optional(),
    types: Joi.any().optional(),
    delais: Joi.any().optional(),
    transport_type: Joi.any().optional(),
  }).unknown(true);

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const VerifyStep_2 = (body) => {
  const schema = Joi.object({
    civility: Joi.object({
      value: Joi.string().required(),
      label: Joi.string().required(),
    })
      .required()
      .label("Civilité"),

    name: Joi.string().required().label("Nom"),
    fullname: Joi.required().label("Nom complet"),
    address1: Joi.string()
      .pattern(/^[A-Za-z0-9].{2,}$/)
      .required()
      .label("Adresse"),
    address2: Joi.any().optional().label("Adresse 2"),
    country: Joi.string().required().label("Pays"),
    postalCode: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required()
      .label("code postal"),
    city: Joi.string().required().min(3).label("Ville"),
    phone1: Joi.string().required().label("Téléphone 1"),
    phone2: Joi.any().optional().label("Téléphone 2"),
    email: Joi.string().required().label("Email"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

export const VerifyUserStep = (body) => {
  const schema = Joi.object({
    civility: Joi.string().required().label("Civilité"),
    firstName: Joi.string().required().label("Nom"),
    lastName: Joi.string().required().label("Prénom"),
    address1: Joi.string()
      .pattern(/^[A-Za-z0-9].{2,}$/)
      .required()
      .label("Adresse"),
    address2: Joi.any().optional().label("Adresse 2"),
    country: Joi.string().required().label("Pays"),
    postalCode: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required()
      .label("Code postal"),
    city: Joi.string().required().min(3).label("Ville"),

    phone1: Joi.string()
      .required()
      .custom((value, helpers) => {
        if (!value) {
          return helpers.error("any.required");
        }
        if (!isValidPhoneNumber(value)) {
          return helpers.error("any.invalid");
        }
        return value;
      })
      .label("Téléphone 1")
      .messages({
        "any.required": "Téléphone 1 est requis.",
        "any.invalid": "Téléphone 1 n'est pas un numéro valide.",
      }),

    phone2: Joi.any().optional().label("Téléphone 2"),

    email: Joi.string()
      .required()
      .custom((value, helpers) => {
        if (!value) {
          return helpers.error("any.required");
        }

        // Séparation de l'email avec ";"
        const emailArray = value.split(";");

        // Vérification de chaque email dans le tableau
        for (const email of emailArray) {
          const emailValidation = Joi.string()
            .email({ tlds: { allow: false } }) // Désactive la vérification des TLD
            .validate(email.trim());

          if (emailValidation.error) {
            return helpers.error("string.email");
          }
        }

        return emailArray; // Retourne le tableau d'emails validé
      })
      .label("Email")
      .messages({
        "any.required": "L'email est requis.",
        "string.email": "L'email n'est pas valide.", // Message unique pour les emails invalides
      }),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

export const expeditorReceiverValidator = (body) => {
  const schema = Joi.object({
    civility: Joi.any().optional(),
    firstName: Joi.string().required().min(1).label("Nom"),
    lastName: Joi.string().required().min(1).label("Prénom"),
    company: Joi.any().allow("").label("Entreprise"),
    address1: Joi.string()
      .pattern(/^[A-Za-z0-9].{2,}$/)
      .required()
      .label("Adresse 1"),
    address2: Joi.any().label("Adresses 2"),
    country: Joi.string().required().label("Pays"),
    postalCode: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required()
      .label("Code postale"),
    city: Joi.string().required().label("Ville"),
    phonecode: Joi.any().optional().label("Code téléphonique"),
    phone1: Joi.string().required().min(4).label("Téléphone 1"),
    phone2: Joi.any().optional().label("Téléphone 2"),
    email: Joi.string().required().label("Email"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

export const checkUECountries = (countryA, countryB) => {
  let a = countriesEU.find(
    ({ iso, name }) =>
      iso.toUpperCase() === countryA.toUpperCase() ||
      name.toUpperCase() === countryA.toUpperCase()
  );
  let b = countriesEU.find(
    ({ iso, name }) =>
      iso.toUpperCase() === countryB.toUpperCase() ||
      name.toUpperCase() === countryB.toUpperCase()
  );

  if (a && b) {
    return true;
  }
  return false;
};

export const VerifyStep_3 = (
  body,
  exnetSpace,
  expeditionType,
  expeditorCountry,
  receiverCountry
) => {
  const schema = Joi.object({
    pickup_date: Joi.string().required().messages({
      "any.required": "La date de ramassage est obligatoire.",
      "string.empty": "La date de ramassage ne doit pas être vide.",
    }),
    delivery_date: Joi.string().required().messages({
      "any.required": "La date de livraison est obligatoire.",
      "string.empty": "La date de livraison ne doit pas être vide.",
    }),
    pickup_instruction: Joi.any().optional(),
    delivery_instruction: Joi.any().optional(),
    pickup_time: Joi.string().required().messages({
      "any.required": "L'heure de ramassage est obligatoire.",
      "string.empty": "L'heure de ramassage ne doit pas être vide.",
    }),
    delivery_time: Joi.string().required().messages({
      "any.required": "L'heure de livraison est obligatoire.",
      "string.empty": "L'heure de livraison ne doit pas être vide.",
    }),
    delais: Joi.string().valid("normal", "spx_1", "spx_2").required().messages({
      "any.required": "Le champ délais est obligatoire.",
      "string.base": "Le champ délais doit être une chaîne de caractères.",
      "string.empty": "Le champ délais ne doit pas être vide.",
      "any.only":
        "Le champ delais doit avoir une valeur parmi normal, spx_1 et spx_2.",
    }),
    type_de_roue: Joi.number().valid(2, 4, 8).required().messages({
      "any.required": "Le champ type de prestation est obligatoire.",
      "number.base": "Le champ type de prestation doit être un nombre.",
      "string.empty": "Le champ type de prestation ne doit pas être vide.",
      "any.only":
        "Le champ type de prestation doit avoir une valeur parmi 2, 4 ou 8",
    }),
  })
    .unknown(true)
    .custom((value, helpers) => {
      const pickupDate = new Date(value.pickup_date);
      const deliveryDate = new Date(value.delivery_date);
      const pickupTime = parseInt(value.pickup_time.replace("h", ""));
      const deliveryTime = parseInt(value.delivery_time.replace("h", ""));

      let minDeliveryDate;

      const isUEcountry = checkUECountries(expeditorCountry, receiverCountry);

      if (exnetSpace === "medical" && isUEcountry) {
        minDeliveryDate = new Date(pickupDate);
        minDeliveryDate.setDate(pickupDate.getDate() + 2);
        minDeliveryDate.setHours(13, 0, 0, 0);
      } else if (!isUEcountry && exnetSpace === "medical") {
        minDeliveryDate = new Date(pickupDate);
        minDeliveryDate.setDate(pickupDate.getDate() + 2);
        minDeliveryDate.setHours(18, 0, 0, 0);
      }

      if (
        new Date(pickupDate).toLocaleDateString() ===
        new Date().toLocaleDateString()
      ) {
        let valeur = new Date().toLocaleTimeString().split(":");
        // valeur = valeur[0] + ':' + valeur[1];

        if (
          parseInt(valeur[0]) > parseInt(value.pickup_time.split(":")[0]) &&
          parseInt(valeur[1]) > parseInt(value.pickup_time.split(":")[1])
        ) {
          return helpers.message(
            "L'heure d'enlèvement est incorrect et doit-être supérieur à " +
              valeur[0] +
              ":" +
              valeur[1]
          );
        }
      }

      // if ((new Date(deliveryDate).getTime() + 360000) <= new Date(minDeliveryDate).getTime()) {
      //   console.log(new Date(deliveryDate).getTime(), new Date(minDeliveryDate).getTime())
      //   return helpers.message(
      //     `La date de livraison doit être au moins ${minDeliveryDate.toLocaleDateString()} mais votre date est  ${deliveryDate.toLocaleDateString()}`
      //   );
      // }

      // if (
      //   (exnetSpace === "medical" &&
      //     (expeditionType === "import" || expeditionType === "couses") &&
      //     deliveryTime < 13)
      // ) {
      //   return helpers.message(
      //     "L'heure de livraison doit être supérieure ou égale à 18h00."
      //   );
      // }

      return value;
    });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const VerifyStep_34 = (
  body,
  exnetSpace,
  expeditionType,
  expeditorCountry,
  receiverCountry
) => {
  const schema = Joi.object({
    pickup_date: Joi.string().required().messages({
      "any.required": "La date de ramassage est obligatoire.",
      "string.empty": "La date de ramassage ne doit pas être vide.",
    }),
    delivery_date: Joi.string().required().messages({
      "any.required": "La date de livraison est obligatoire.",
      "string.empty": "La date de livraison ne doit pas être vide.",
    }),
    pickup_instruction: Joi.any().optional(),
    delivery_instruction: Joi.any().optional(),
    pickup_time: Joi.string().required().messages({
      "any.required": "L'heure de ramassage est obligatoire.",
      "string.empty": "L'heure de ramassage ne doit pas être vide.",
    }),
    delivery_time: Joi.string().required().messages({
      "any.required": "L'heure de livraison est obligatoire.",
      "string.empty": "L'heure de livraison ne doit pas être vide.",
    }),
  })
    .unknown(true)
    .custom((value, helpers) => {
      const pickupDate = new Date(value.pickup_date);
      const deliveryDate = new Date(value.delivery_date);
      const pickupTime = parseInt(value.pickup_time.replace("h", ""));
      const deliveryTime = parseInt(value.delivery_time.replace("h", ""));

      let minDeliveryDate;

      const isUEcountry = checkUECountries(expeditorCountry, receiverCountry);

      if (exnetSpace === "medical" && isUEcountry) {
        minDeliveryDate = new Date(pickupDate);
        minDeliveryDate.setDate(pickupDate.getDate() + 2);
        minDeliveryDate.setHours(13, 0, 0, 0);
      } else if (!isUEcountry && exnetSpace === "medical") {
        minDeliveryDate = new Date(pickupDate);
        minDeliveryDate.setDate(pickupDate.getDate() + 2);
        minDeliveryDate.setHours(18, 0, 0, 0);
      }

      if (
        new Date(pickupDate).toLocaleDateString() ===
        new Date().toLocaleDateString()
      ) {
        let valeur = new Date().toLocaleTimeString().split(":");
        // valeur = valeur[0] + ':' + valeur[1];

        if (
          parseInt(valeur[0]) > parseInt(value.pickup_time.split(":")[0]) &&
          parseInt(valeur[1]) > parseInt(value.pickup_time.split(":")[1])
        ) {
          return helpers.message(
            "L'heure d'enlèvement est incorrect et doit-être supérieur à " +
              valeur[0] +
              ":" +
              valeur[1]
          );
        }
      }

      // if ((new Date(deliveryDate).getTime() + 360000) <= new Date(minDeliveryDate).getTime()) {
      //   console.log(new Date(deliveryDate).getTime(), new Date(minDeliveryDate).getTime())
      //   return helpers.message(
      //     `La date de livraison doit être au moins ${minDeliveryDate.toLocaleDateString()} mais votre date est  ${deliveryDate.toLocaleDateString()}`
      //   );
      // }

      // if (
      //   (exnetSpace === "medical" &&
      //     (expeditionType === "import" || expeditionType === "couses") &&
      //     deliveryTime < 13)
      // ) {
      //   return helpers.message(
      //     "L'heure de livraison doit être supérieure ou égale à 18h00."
      //   );
      // }

      return value;
    });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const verifyStep_4 = (body) => {
  const schema = Joi.object({
    package_description: Joi.string().required().messages({
      "string.base":
        "La description du colis doit être une chaîne de caractères.",
      "any.required": "La description du colis est requise.",
      "string.empty": "La description du colis ne doit pas être vide.",
    }),
    valeur_douane: Joi.number().min(2).required().messages({
      "number.base": "La valeur en douane doit être un nombre.",
      "any.required": "La valeur en douane est requise.",
      "number.min": "La valeur en douane doit être supérieure ou égale à 2.",
    }),
    // documents: Joi.array().items(Joi.any()).min(1).required().messages({
    //   "any.required": "Les documents sont requis.",
    //   "array.min": "Au moins un document doit être fourni.",
    // }),
  }).unknown(true);

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const verifyStepCourse = (body) => {
  const schema = Joi.object({
    package_description: Joi.string().required().messages({
      "string.base":
        "La description du colis doit être une chaîne de caractères.",
      "any.required": "La description du colis est requise.",
      "string.empty": "La description du colis ne doit pas être vide.",
    }),
    valeur_douane: Joi.number().min(2).required().messages({
      "number.base": "La valeur en douane doit être un nombre.",
      "any.required": "La valeur en douane est requise.",
      "number.min": "La valeur en douane doit être supérieure ou égale à 2.",
    }),
    delaisBack: Joi.string().allow("").optional().messages({
      "string.base":
        "Delais de livraison retour doit être une chaîne de caractères.",
      "any.required": "Delais de livraison retour est requise.",
      "any.empty": "Delais de livraison retour est requise.",
    }),
    delais: Joi.string().allow("").optional().messages({
      "string.base": "Delais de livraison doit être une chaîne de caractères.",
      "any.required": "Delais de livraison est requise.",
      "any.empty": "Delais de livraison est requise.",
    }),
    // documents: Joi.array().items(Joi.any()).min(1).required().messages({
    //   "any.required": "Les documents sont requis.",
    //   "array.min": "Au moins un document doit être fourni.",
    // }),
  }).unknown(true);

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const exportStep_5 = (body, exnetSpace) => {
  let itemSchema;
  if (exnetSpace.toLowerCase() === "medical") {
    itemSchema = Joi.object({
      length: Joi.any().optional().messages({
        "number.base": "La longueur doit être un nombre.",
        "any.required": "La longueur est requise.",
      }),
      width: Joi.any().optional().messages({
        "number.base": "La largeur doit être un nombre.",
        "any.required": "La largeur est requise.",
      }),
      height: Joi.any().optional().messages({
        "number.base": "La hauteur doit être un nombre.",
        "any.required": "La hauteur est requise.",
      }),
      weight: Joi.number().required().messages({
        "number.base": "Le poids doit être un nombre.",
        "any.required": "Le poids est requis.",
      }),
      devises: Joi.string().valid("usd", "eur", "gbp").required().messages({
        "string.base": "La devise doit être une chaîne de caractères.",
        "any.required": "La devise est requise.",
        "any.empty": "La devise est requise.",
        "any.only":
          "La devise doit être l'une des valeurs suivantes : {{valids}}.",
      }),
      weight_volume: Joi.number().required().messages({
        "number.base": "Le poids volumétrique doit être un nombre.",
        "any.required": "Le poids volumétrique est requis.",
      }),
      description: Joi.string().required().messages({
        "string.base": "La description doit être une chaîne de caractères.",
        "any.required": "La description est requise.",
        "any.empty": "La description est requise.",
      }),
      incoterm: Joi.string().required().messages({
        "string.base": "L'incoterm doit être une chaîne de caractères.",
        "any.required": "L'incoterm est requis.",
        "any.empty": "L'incoterm est requise.",
      }),
      value_in_douane: Joi.number().min(2).required().messages({
        "number.base": "La valeur en douane doit être un nombre.",
        "any.required": "La valeur en douane est requise.",
      }),
      // documents: Joi.any().required().messages({
      //   "any.required": "Les documents sont requis.",
      // }),
      ref: Joi.any().optional(),
    }).unknown(true);
  } else {
    itemSchema = Joi.object({
      length: Joi.number().required().messages({
        "number.base": "La longueur doit être un nombre.",
        "any.required": "La longueur est requise.",
      }),
      width: Joi.number().required().messages({
        "number.base": "La largeur doit être un nombre.",
        "any.required": "La largeur est requise.",
      }),
      height: Joi.number().required().messages({
        "number.base": "La hauteur doit être un nombre.",
        "any.required": "La hauteur est requise.",
      }),
      weight: Joi.number().required().messages({
        "number.base": "Le poids doit être un nombre.",
        "any.required": "Le poids est requis.",
      }),
      weight_volume: Joi.number().required().messages({
        "number.base": "Le poids volumétrique doit être un nombre.",
        "any.required": "Le poids volumétrique est requis.",
      }),
      devises: Joi.string().valid("usd", "eur", "gbp").required().messages({
        "string.base": "La devise doit être une chaîne de caractères.",
        "any.required": "La devise est requise.",
        "any.empty": "La devise est requise.",
        "any.only":
          "La devise doit être l'une des valeurs suivantes : {{valids}}.",
      }),
      description: Joi.string().required().messages({
        "string.base": "La description doit être une chaîne de caractères.",
        "any.required": "La description est requise.",
        "any.empty": "La description est requise.",
      }),
      incoterm: Joi.string().required().messages({
        "string.base": "L'incoterm doit être une chaîne de caractères.",
        "any.required": "L'incoterm est requis.",
        "any.empty": "L'incoterm est requise.",
      }),
      value_in_douane: Joi.number().min(2).required().messages({
        "number.base": "La valeur en douane doit être un nombre.",
        "any.required": "La valeur en douane est requise.",
      }),
      // documents: Joi.any().required().messages({
      //   "any.required": "Les documents sont requis.",
      // }),
      ref: Joi.any().optional(),
    }).unknown(true);
  }

  const packageSchema = Joi.array().items(itemSchema);

  const { error } = packageSchema.validate(body, { allowUnknown: true });

  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;
    return {
      valid: false,
      error: true,
      errorData: {
        field: fieldName,
        message: errorMessage,
        field2: firstError.path[firstError.path.length - 1],
      },
    };
  }
  return { valid: true };
};

export const verifyStep_Course = (body) => {
  const itemSchema = Joi.object({}).unknown(true);

  const { error } = itemSchema.validate(body, { allowUnknown: true });

  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const verifyProformaData = (body) => {
  const schema = Joi.object({
    composition: Joi.string().required().messages({
      "string.base": "La composition doit être une chaîne de caractères.",
      "any.required": "La composition est obligatoire.",
    }),
    description: Joi.string().required().valid("").messages({
      "string.base": "La description doit être une chaîne de caractères.",
      "any.required": "La description est obligatoire.",
      "any.empty": "La description ne doit pas être vide.",
    }),
    hs_code: Joi.number().min(1).required().messages({
      "number.base": "Le code HS doit être une chaîne de caractères.",
      "number.min": "Le prix hors taxe doit être supérieure à 0",
      "any.required": "Le code HS est obligatoire.",
    }),
    manufacturer: Joi.string().required().messages({
      "string.base": "Le fabricant doit être une chaîne de caractères.",
      "any.required": "Le fabricant est obligatoire.",
    }),
    origin: Joi.string().required().messages({
      "string.base": "L'origine doit être une chaîne de caractères.",
      "string.length": "L'origine doit avoir une longueur de 2 caractères.",
      "any.required": "L'origine est obligatoire.",
    }),
    prix_unitaire: Joi.number().min(1).required().messages({
      "number.base": "Le prix unitaire doit être un nombre.",
      "number.min": "Le prix unitaire ne peut pas être négatif.",
      "any.required": "Le prix unitaire est obligatoire.",
    }),
    qte: Joi.number().integer().min(1).required().messages({
      "number.base": "La quantité doit être un nombre entier.",
      "number.integer": "La quantité doit être un nombre entier.",
      "number.min": "La quantité doit être d'au moins 1.",
      "any.required": "La quantité est obligatoire.",
    }),
  }).unknown(true);
  const proforma = Joi.array().items(schema);

  const { error } = proforma.validate(body, { allowUnknown: true });
  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const verifyProformaHeader = (body) => {
  const schemaHeader = Joi.object({
    date_export: Joi.date().iso().required().messages({
      "date.base": "La date d'export doit être une date valide.",
      "date.iso": "La date d'export doit être au format ISO (AAAA-MM-JJ).",
      "any.required": "La date d'export est obligatoire.",
    }),
    nature: Joi.string().required().messages({
      "string.base": "La nature doit être une chaîne de caractères.",
      "any.required": "La nature est obligatoire.",
    }),
    num_proforma: Joi.string().required().messages({
      "string.base":
        "Le numéro de proforma doit être une chaîne de caractères.",
      "any.required": "Le numéro de proforma est obligatoire.",
    }),
  }).unknown(true);

  const { error } = schemaHeader.validate(body, { allowUnknown: true });
  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const verifyContact = (body) => {
  const schema = Joi.object({
    civility: Joi.any().optional(),
    firstName: Joi.string().required().min(1).label("Nom"),
    lastName: Joi.string().required().min(1).label("Prénom"),
    company: Joi.any().allow("").label("Entreprise"),
    address1: Joi.string()
      .pattern(/^[A-Za-z0-9].{2,}$/)
      .required()
      .label("Address"),
    address2: Joi.any(),
    country: Joi.string().required().label("Pays"),
    postal_code: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required()
      .label("Code postale"),
    city: Joi.string().required().label("Ville"),
    phonecode: Joi.any().optional().label("Code téléphonique"),
    telephone1: Joi.string().required().min(4).label("Téléphone 1"),
    telephone2: Joi.any().optional(),
    email1: Joi.string().required().label("Email 1"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};

// New stepper validation
export const firstStepperValidator = (body) => {
  const schema = Joi.object({
    user_id: Joi.number().required().label("Client"),
    bill_center_id: Joi.number().required().label("Centre de facturation"),
    customer_ref: Joi.any().optional().label("Référence"),
    tracking_mail: Joi.string()
      .allow(null, "") // Permet au champ d'être vide
      .custom((value, helpers) => {
        if (value === null || value === "") {
          return value; // Si le champ est vide, il passe sans erreur
        }

        // Séparation de l'email avec ";"
        const emailArray = value.split(";");

        // Vérification de chaque email dans le tableau
        for (const email of emailArray) {
          const emailValidation = Joi.string()
            .email({ tlds: { allow: false } }) // Désactive la vérification des TLD
            .validate(email.trim());

          if (emailValidation.error) {
            return helpers.error("string.email");
          }
        }

        return emailArray; // Retourne le tableau d'emails validé
      })
      .label("Email")
      .messages({
        "any.required": "L'email est requis.",
        "string.email": "L'email n'est pas valide.", // Message unique pour les emails invalides
      }),
    username: Joi.any().required().label("Nom d'utilisateur"),

    nomContact: Joi.any().optional().label("Nom"),
    types: Joi.any().optional().label("Types"),
    delais: Joi.any().optional().label("Délais"),
    transport_type: Joi.any().optional().label("Transport"),
  })
    .messages(customMessages)
    .unknown(true);

  return returnError(schema.validate(body));
};
