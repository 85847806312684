import React, { useEffect, useState } from "react";
import { TypeDiagnoBagDataStore } from "../../../states/tableData";
import { Selector } from "../../../components/Utils/Selectors/Selector";
import { ErrorStore } from "../../../store/inputError";
import { InputComponent3 } from "../../../components/InputComponent/InputComponent3";
import { ButtonComponent } from "../../../components/ButtonComponent";
import { adminPackageService } from "../../../services/admin/packageService";
import { toast } from "react-toastify";

export const TypeEmballageForm = ({ setOpen, action = "add" }) => {
  const data = TypeDiagnoBagDataStore((state) => state.data);
  const modifyData = TypeDiagnoBagDataStore((state) => state.modifyData);
  const errorInformation = ErrorStore((state) => state.errorField);
  const addData = TypeDiagnoBagDataStore((state) => state.addData);
  const setDataContent = TypeDiagnoBagDataStore(
    (state) => state.setDataContent
  );
  const updateData = TypeDiagnoBagDataStore((state) => state.updateData);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    try {
      if (data.libelle && data.libelle.length > 0) {
        setIsLoading(true);
        const toastsId = toast.loading("Création en cours ...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        adminPackageService.createDiagnobag({ libelle: data.libelle,longueur:data.longueur,largeur:data.largeur }).then(
          (response) => {
            toast.update(toastsId, {
              render: "Enregistrement effectué avec succès",
              type: "success",
              isLoading: false,
              autoClose: 1000,
              position: "top-center",
            });
            addData({ ...response.data.data, active: 1 });
            setDataContent({});
            setIsLoading(false);
            setOpen(false);
          },
          (error) => {
            toast.update(toastsId, {
              render: "Erreur lors de l'enregistrement",
              type: "error",
              isLoading: false,
              autoClose: 1000,
              position: "top-center",
            });
            console.log(error);
            setIsLoading(false);
          }
        );
      } else {
        toast.error("Veuillez remplir le champ Libellé");
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors de l'enregistrement");
    }
  };

  const handleUpdateSubmit = () => {
    try {
      if (data.libelle && data.libelle.length > 0) {
        setIsLoading(true);
        const toastsId = toast.loading("Modification en cours ...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        const packageTypeId = action;
        adminPackageService
          .updateDiagnobag({ libelle: data.libelle,longueur:data.longueur,largeur:data.largeur, id: packageTypeId })
          .then(
            (response) => {
              toast.update(toastsId, {
                render: "Modification effectuée avec succès",
                type: "success",
                isLoading: false,
                autoClose: 1000,
                position: "top-center",
              });
              updateData(packageTypeId, { libelle: data.libelle,longueur:data.longueur,largeur:data.largeur });
              setDataContent({});
              setIsLoading(false);
              setOpen(false);
            },
            (error) => {
              toast.update(toastsId, {
                render: "Erreur lors de la modification",
                type: "error",
                isLoading: false,
                autoClose: 1000,
                position: "top-center",
              });
              console.log(error);
              setIsLoading(false);
            }
          );
      } else {
        toast.error("Veuillez remplir le champ Libellé");
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors de la modification");
    }
  };

  useEffect(() => {
    const fecthDetails = async () => {
      adminPackageService.getDiagnobagById({ id: action }).then(
        (response) => {
          console.log(response.data.data[0]);
          setDataContent(response.data.data[0]);
        },
        (error) => {
          console.log(error);
        }
      );
    };

    if (action !== "add") {
      fecthDetails();
    }
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <InputComponent3
        onChange={(valeur) => {
          modifyData("libelle", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={"Libelle".toUpperCase()}
        value={data.libelle}
        label="Libelle *"
        withLabel={true}
        isError={errorInformation.field === "libelle"}
      />

      <InputComponent3
        onChange={(valeur) => {
          modifyData("longueur", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={"Longueur".toUpperCase()}
        value={data.longueur}
        label="Longueur *"
        type="number"
        withLabel={true}
        isError={errorInformation.field === "Longueur"}
      />

      <InputComponent3
        onChange={(valeur) => {
          modifyData("largeur", valeur);
        }}
        type="number"
        errorMessage={errorInformation.message}
        placeholder={"Largeur".toUpperCase()}
        value={data.largeur}
        label="Largeur *"
        withLabel={true}
        isError={errorInformation.field === "largeur"}
      />

      <ButtonComponent
        onClick={action === "add" ? handleSubmit : handleUpdateSubmit}
        title={action === "add" ? "Enregistrer" : "Modifier"}
        disabled={isLoading}
      />
    </div>
  );
};
