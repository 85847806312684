import React, { useEffect, useState } from 'react'
import { Liste } from './liste'
import { EmballageDataStore } from '../../../states/tableData'
import ModalComponent2 from '../../../components/modal/ModalComponent'
import { TypeEmballageForm } from './form'
import { ButtonComponent } from '../../../components/ButtonComponent'

export const AdminSondePage = () => {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('Nouveau sonde')

  return (
    <>

      <section
        className={"flex  items-center justify-between mt-10"}
      >
        <h1>Liste des types de sondes</h1>

        <ButtonComponent
          onClick={() => setOpen(true)}
          title="Ajouter un type de sonde"
        />

      </section>

      <section>
        <Liste />
      </section>
      <ModalComponent2 title={title} open={open} setOpen={setOpen}>
        <TypeEmballageForm setOpen={setOpen} />
      </ModalComponent2>
    </>
  )
}
