export const stateOptions = [
  { label: "Tout", value: "all" },
  { label: "Actif", value: "actif" },
  { label: "Inactif", value: "inactif" },
];

const emballageType = [
  { value: "5Kg", label: "5Kg" },
  { value: "10Kg", label: "10Kg" },
  { value: "15Kg", label: "15Kg" },
  { value: "20Kg", label: "20Kg" },
  { value: "30Kg", label: "30Kg" },
];

const emballageType2 = [
  { value: "4L", label: "4L" },
  { value: "11L", label: "11L" },
  { value: "16L", label: "16L" },
  { value: "20L", label: "20L" },
  { value: "30L", label: "30L" },
  { value: "33L", label: "33L" },
];

const emballageType3 = [
  { value: "0,75L", label: "0,75L" },
  { value: "2L", label: "2L" },
  { value: "3L", label: "3L" },
  { value: "6,25L", label: "6,25L" },
  { value: "12,50L", label: "12,50L" },
  { value: "18,75L", label: "18,75L" },
  { value: "25L", label: "25L" },
  { value: "37,50L", label: "37,50L" },
];

const emballageType4 = [
  { value: "PETIT", label: "PETIT" },
  { value: "MOYEN", label: "MOYEN" },
  { value: "GRAND", label: "GRAND" },
];

const sondeList = [
  {
    value: "classique",
    label: "Sonde T° classique(-30°C à +70°C) ",
    minTemperature: -30,
    maxTemperature: 70,
  },
  {
    value: "classique-reutilisable",
    label: "Sonde T° classique réutilisable(-30°C à +70°C) ",
    minTemperature: -30,
    maxTemperature: 70,
  },
  {
    value: "geolocalisable",
    label: "Sonde T° Géolocalisable(-10°C à 55°C)  ",
    minTemperature: -10,
    maxTemperature: 55,
  },
  {
    value: "geolocalisable-glace-carbonique",
    label: "Sonde T° Géolocalisable glace carbonique(-95°C à 55°C)",
    minTemperature: -95,
    maxTemperature: 55,
  },
  {
    value: "glace-carbonique",
    label: "Sonde T° glace de carbonique(-95°C à 55°C)",
    minTemperature: -95,
    maxTemperature: 55,
  },
];

const diagnobagsTypeList = [
  { value: "A2", label: "A2" },
  { value: "A3", label: "A3" },
  { value: "A4", label: "A4" },
];
