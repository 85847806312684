import React, { useCallback, useEffect, useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import useFormValues, { useBasicFormValues } from "../../hooks/useFormValues";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import { userService } from "../../services/user/UserService";
import ExnetTypeSelector from "../Utils/Selectors/ExnetTypeSelector";
import UsersSelector from "../Utils/Selectors/UsersSelector";
import ModalUI from "../Utils/Modal/Modal";
import FormUploadSection from "../Utils/FormUploadSection";
import { SwitchComponent } from "../Utils/Index";
import centreFacturationService from "../../services/centreFacturation/centreFacturationService";
import { getFileUrl, jsonToFormData } from "../../utils";
import ClientSelector from "../Utils/Selectors/ClientSelector";
import Loader from "../Utils/Loader";
import { toast } from "react-toastify";
import { comparerObjets } from "../../utils/Functions/otherFunctions";
import PhoneInput from "react-phone-input-2";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";
import { returnValue } from "../../utils/Functions/functions";
import { BillCenterValidator } from "../../utils/validatoresFunction";
import { ErrorStore } from "../../store/inputError";
import InputComponent from "../InputComponent/InputComponent";


const CenterForm = ({
  modalFormContent,
  newData,
  editable,
  onSubmit,
  onClose,
}) => {
  const {
    data: centerData,
    setFormValue,
    setData,
  } = useBasicFormValues({ type: "", country: "fr" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [makeUpdate, setmakeUpdate] = useState(false);
  const errorInformation = ErrorStore((state) => state.errorField);
  const setMessageError = ErrorStore((state) => state.setMessageError);
  const resetErrorField = ErrorStore((state) => state.resetErrorField);
  useEffect(() => {
    if (modalFormContent) {
      // console.log('la data ', modalFormContent)
      setData((d) => ({ ...d, ...modalFormContent }));
    }
  }, [modalFormContent]);

  const submit = useCallback(

    async (e) => {

      try {
        e.preventDefault();
        const { valid, errorData } = BillCenterValidator(centerData);
        if (valid) {
          const updated = centerData.id;

          setIsSubmitting(true);
          let newData = comparerObjets(centerData, updated);
          // let submitData = jsonToFormData({
          //   ...newData,
          //   actif: updated ? centerData.actif : 1
          // });
          let submitData = jsonToFormData({
            ...centerData,
            actif: updated ? centerData.actif : 1,
          });

          if (updated) {
            submitData.id = updated;
          }
          // console.log({ submitData })
          const request = updated
            ? centreFacturationService.editCentreFacturation
            : centreFacturationService.addCentreFacturation;

          /*   if (!updated) {
              submitData.actif = 1
            } */

          const { data } = await request(submitData);
          //console.log({ data })
          if (data.success) {
            onSubmit?.(updated ? data.data : { ...data.data, actif: 1 });
          } else {
            toast.error("Une erreur lors de la soumission de votre demande", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
        else {
          setMessageError({
            ...errorData
          })
          toast.error(errorData.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error(error);
        toast.error("Une erreur lors de la soumission de votre demande", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [centerData, onSubmit]
  );

  const onChangeSelect = (data) => {
    console.log(data);

    setFormValue("customer_id", data?.value);
    let customer_id = returnValue(data?.value);
    let address = returnValue(data?.address);
    let address2 = returnValue(data?.address2);
    let postal_code = returnValue(data?.postal_code);
    let city = returnValue(data?.city);
    let telephone = data?.telephone;
    let telephone1 = returnValue(data?.telephone) !== "" ? data?.telephone : returnValue(data?.telephone1);
    let telephone2 = returnValue(data?.telephone2);
    let country = returnValue(data?.country);
    let email_facturation = returnValue(data?.email_facturation) !== "" ? data?.email_facturation : returnValue(data?.invoice_mail);
    let responsable_comptable = returnValue(data?.responsable_comptable);
    let intra_vatnumber = returnValue(data?.intra_vatnumber);
    let email1 = returnValue(data?.email1);
    let email2 = returnValue(data?.email2);
    let email3 = returnValue(data?.email3);
    let numero_siren = returnValue(data?.siren) !== "" ? data?.siren : returnValue(data?.numero_siren);
    let eori = returnValue(data?.numero_eori);
    let numero_siret = returnValue(data?.numero_siret);


    setData({
      ...centerData,
      customer_id,
      address,
      address2,
      city,
      postal_code,
      telephone1,
      telephone2,
      country,
      responsable_comptable,
      email_facturation,
      intra_vatnumber,
      email1,
      email2,
      email3,
      eori,
      numero_siren,
      numero_siret
    });
    resetErrorField()
  };

  useEffect(() => {
    resetErrorField()
  }, [])

  return (
    <div className="p-3">
      {!newData && (
        <div className={"flex justify-end"}>
          <button
            type="submit"
            className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
            onClick={() => setmakeUpdate(!makeUpdate)}
          >
            <span className={"fa fa-edit"}></span>
          </button>
        </div>
      )}

      <form onSubmit={submit}>
        <div className="grid gap-6 mb-3 md:grid-cols-2">
          <div className="text-left">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Choix du client
            </label>
            <ClientSelector
              showLabel={false}
              selectedValue={centerData.customer_id}
              onSelect={(v) => {
                onChangeSelect(v);
              }}
              type={centerData?.type?.split(",")?.[0] || "all"}
              filterOnAll
            //type="all"
            />
            {errorInformation.field === "customer_id" && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorInformation.message}
              </p>
            )}
          </div>

          <div className="text-left">
            <InputComponent
              value={centerData.billing_center_name}
              onChange={(valeur) => setFormValue("billing_center_name", valeur)}
              errorMessage={errorInformation.message}
              placeholder={" Nom du centre de facturation *".toUpperCase()}
              label="Nom du centre de facturation *"
              withLabel={true}
              isError={errorInformation.field === "billing_center_name"}
            />

          </div>

          <div className="text-left">

            <InputComponent
              value={returnValue(centerData.address)}
              onChange={(valeur) => setFormValue("address", valeur)}
              errorMessage={errorInformation.message}
              placeholder={"Adresse*".toUpperCase()}
              label=" Adresse de facturation *"
              withLabel={true}
              isError={errorInformation.field === "address"}
            />
          </div>

          <div className="text-left">

            <InputComponent
              value={returnValue(centerData.address2)}
              onChange={(valeur) => setFormValue("address2", valeur)}
              errorMessage={errorInformation.message}
              placeholder={" Complément d'adresse".toUpperCase()}
              label=" Complément d'adresse"
              withLabel={true}
              isError={errorInformation.field === "address2"}
            />


          </div>

          <div className="text-left">
            <InputComponent
              value={returnValue(centerData.city)}
              onChange={(valeur) => setFormValue("city", valeur)}
              errorMessage={errorInformation.message}
              placeholder={"Ville*".toUpperCase()}
              label="Ville*"
              withLabel={true}
              isError={errorInformation.field === "city"}
            />
          </div>

          <div className="text-left">

            <InputComponent
              value={returnValue(centerData.postal_code)}
              onChange={(valeur) => setFormValue("postal_code", valeur)}
              errorMessage={errorInformation.message}
              placeholder={"Code Postal*".toUpperCase()}
              label="Code Postal*"
              withLabel={true}
              isError={errorInformation.field === "postal_code"}
            />

          </div>

          <div className="text-left">
            <CountriesSelector
              selectedValue={centerData.country}
              onSelect={(v) => {
                setFormValue("country", v.value);
              }}
              isError={errorInformation.field === "country"}
              errorMessage={errorInformation.message}
            // isDisabled={!editable}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="telephone"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Téléphone*
            </label>

            <PhoneInput
              country={
                getCountryNameOrCode(
                  getCountryNameOrCode(centerData?.country),
                  true
                ) ?? "fr"
              }
              defaultValue={centerData.telephone1}
              value={centerData.telephone1}
              onChange={(value, country, e, formattedValue) => {
                setFormValue("telephone1", formattedValue);
              }}
              inputClass={"form-controle"}
              countryCodeEditable={true}
              disableDropdown={true}
            />
            {errorInformation.field === "telephone1" && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorInformation.message}
              </p>
            )}
          </div>
          <div className="text-left">
            <InputComponent
              value={returnValue(centerData.email_facturation)}
              onChange={(valeur) => setFormValue("email_facturation", valeur)}
              errorMessage={errorInformation.message}
              placeholder={" Email de facturation*".toUpperCase()}
              label="Email de facturation*"
              withLabel={true}
              isError={errorInformation.field === "email_facturation"}
            />


          </div>

          <div className="text-left">
            {/*  {centerData.type} */}
            <ExnetTypeSelector
              multiple={false}
              is
              value={centerData?.type}
              onChange={(v) => {
                setFormValue("type", v?.value);
              }}
            />
            {errorInformation.field === "type" && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {errorInformation.message}
              </p>
            )}
          </div>
        </div>

        <div className="w-full h-[1px] bg-gray-300 my-2" />

        <div className="grid gap-6 mb-3 md:grid-cols-3">
          <div className="text-left">
            <InputComponent
              value={returnValue(centerData.dirigeant)}
              onChange={(valeur) => setFormValue("dirigeant", valeur)}
              errorMessage={errorInformation.message}
              placeholder={"Contact".toUpperCase()}
              label="Contact (Nom / Prénoms)"
              withLabel={true}
              isError={errorInformation.field === "dirigeant"}
            />

          </div>
          <div className="grid col-span-2 gap-6 md:grid-cols-2">
            <div className="text-left">
              <label
                htmlFor="dirigeant_tel"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Téléphone
              </label>

              <PhoneInput
                country={
                  getCountryNameOrCode(
                    getCountryNameOrCode(centerData?.country),
                    true
                  ) ?? "fr"
                }
                defaultValue={centerData.dirigeant_tel}
                value={centerData.dirigeant_tel}
                onChange={(value, country, e, formattedValue) => {
                  setFormValue("dirigeant_tel", formattedValue);
                }}
                inputClass={"form-controle"}
                countryCodeEditable={true}
                disableDropdown={false}
              />
              {errorInformation.field === "dirigeant_tel" && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {errorInformation.message}
                </p>
              )}
            </div>

            <div className="text-left">
              <InputComponent
                value={returnValue(centerData.dirigeant_email)}
                onChange={(valeur) => setFormValue("dirigeant_email", valeur)}
                errorMessage={errorInformation.message}
                placeholder={"Dirigeant Email".toUpperCase()}
                label="Dirigeant Email"
                withLabel={true}
                isError={errorInformation.field === "dirigeant_email"}
              />

            </div>
          </div>
        </div>

        <div className="w-full h-[1px] bg-gray-300 my-2" />

        <div className="grid gap-6 mb-3 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="responsable_comptable"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              <span className="font-semibold">Resp. Compta.</span> (Nom/Prénoms)
            </label>
            <input
              type="text"
              id="responsable_comptable"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={returnValue(centerData.responsable_comptable)}
              onChange={(e) =>
                setFormValue("responsable_comptable", e.target.value)
              }
            />
          </div>

          <div className="grid col-span-2 gap-6 md:grid-cols-2">
            <div className="text-left">
              <label
                htmlFor="responsable_comptable_tel"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Téléphone
              </label>

              <PhoneInput
                country={
                  getCountryNameOrCode(
                    getCountryNameOrCode(centerData?.country),
                    true
                  ) ?? "fr"
                }
                defaultValue={centerData.responsable_comptable_tel}
                value={centerData.responsable_comptable_tel}
                onChange={(value, country, e, formattedValue) => {
                  setFormValue("responsable_comptable_tel", formattedValue);
                }}
                inputClass={"form-controle"}
                countryCodeEditable={true}
                disableDropdown={false}
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="responsable_comptable_email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="text"
                id="responsable_comptable_email"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                value={returnValue(centerData.responsable_comptable_email)}
                onChange={(e) =>
                  setFormValue("responsable_comptable_email", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full h-[1px] bg-gray-300 my-2" />
        <div className="grid gap-6 mb-3 md:grid-cols-2">
          <div className="text-left">
            <InputComponent
              value={returnValue(centerData.intra_vatnumber)}
              onChange={(valeur) => console.log(valeur)}
              errorMessage={errorInformation.message}
              placeholder={" N° TVA Intracommunautaire*".toUpperCase()}
              label=" N° TVA Intracommunautaire*"
              withLabel={true}
              isError={errorInformation.field === "intra_vatnumber"}
            />


          </div>
          <div className="text-left">
            <InputComponent
              value={returnValue(centerData.numero_siren)}
              onChange={(valeur) => console.log(valeur)}
              errorMessage={errorInformation.message}
              placeholder={"N° SIREN".toUpperCase()}
              label="N° SIREN"
              withLabel={true}
              isError={errorInformation.field === "numero_siren"}
            />

          </div>
          <div className="text-left">
            <InputComponent
              value={returnValue(centerData.numero_siret)}
              onChange={(valeur) => console.log(valeur)}
              errorMessage={errorInformation.message}
              placeholder={"N° SIRET".toUpperCase()}
              label="N° SIRET"
              withLabel={true}
              isError={errorInformation.field === "numero_siret"}
            />


          </div>
          <div className="text-left">
            <InputComponent
              value={returnValue(centerData.eori)}
              onChange={(valeur) => console.log(valeur)}
              errorMessage={errorInformation.message}
              placeholder={"N° EORI".toUpperCase()}
              label="N° EORI"
              withLabel={true}
              isError={errorInformation.field === "eori"}
            />

          </div>
        </div>

        <div className="grid gap-6 mb-3 md:grid-cols-3">
          <div className="text-left">
            <label
              htmlFor="email1"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email
            </label>
            <input
              type="text"
              id="email1"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={returnValue(centerData.email1)}
              onChange={(e) => setFormValue("email1", e.target.value)}
            />
          </div>
          <div className="text-left">
            <label
              htmlFor="email2"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email 2
            </label>
            <input
              type="text"
              id="email2"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={returnValue(centerData.email2)}
              onChange={(e) => setFormValue("email2", e.target.value)}
            />
          </div>

          <div className="text-left">
            <label
              htmlFor="email3"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Email 3
            </label>
            <input
              type="text"
              id="email3"
              className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
              value={returnValue(centerData.email3)}
              onChange={(e) => setFormValue("email3", e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="mb-3">
            <FormUploadSection
              logo={centerData.logo}
              header={centerData.header}
              footer={centerData.footer}
              onDataChange={setFormValue}
            />
          </div>
          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="valeur_customer"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Afficher valeur commerciale sur bordereau
            </label>
            <SwitchComponent
              id="valeur_customer"
              label="valeur_customer"
              checked={+centerData.valeur_customer === 1}
              onChange={(checked) => {
                setFormValue("valeur_customer", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>

          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="customer_name"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Afficher nom client sur bordereau
            </label>
            <SwitchComponent
              id="customer_name"
              label="customer_name"
              checked={+centerData.customer_name === 1}
              onChange={(checked) => {
                setFormValue("customer_name", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>

          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="active_mail_instruction"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Afficher mail d'instruction
            </label>
            <SwitchComponent
              id="active_mail_instruction"
              label="active_mail_instruction"
              checked={+centerData.active_mail_instruction === 1}
              onChange={(checked) => {
                setFormValue("active_mail_instruction", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>

          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="mailing"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Lettre d'info / mailing
            </label>
            <SwitchComponent
              id="mailing"
              label="mailing"
              checked={+centerData.mailing === 1}
              onChange={(checked) => {
                setFormValue("mailing", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>

          <div className="flex items-center justify-between mb-3">
            <label
              htmlFor="deux_bordereaux"
              className="text-sm font-medium text-gray-900 cursor-pointer"
            >
              Deux borderaux
            </label>
            <SwitchComponent
              id="deux_bordereaux"
              label="deux_bordereaux"
              checked={+centerData.deux_bordereaux === 1}
              onChange={(checked) => {
                setFormValue("deux_bordereaux", checked ? 1 : 0);
              }}
              checkedColor="#098721"
            />
          </div>
        </div>

        <div className="flex justify-end mt-10">
          <div
            onClick={() => onClose?.()}
            className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-3"
          >
            Annuler
          </div>
          {(editable || makeUpdate) && (
            <button
              type="submit"
              className={`${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"
                } text-white bg-[#098721] hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">Modifier</span>
              {isSubmitting && <Loader />}
            </button>
          )}

          {newData && (
            <button
              type="submit"
              className={`${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"
                } text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">{centerData.id ? "Modifier" : "Enregistrer"} </span>
              {isSubmitting && <Loader />}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default function CreateCenterForm({
  withoutModal,
  modalFormContent,
  editable,
  dataType,
  isOpen,
  onClose,
  onSubmit,
}) {
  // console.log({ modalFormContent })

  return (
    <>
      {withoutModal ? (
        <CenterForm
          modalFormContent={modalFormContent}
          editable={editable}
          dataType={dataType}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      ) : (
        <>
          <ModalUI
            isOpen={isOpen}
            onClose={onClose}
            title={
              modalFormContent
                ? "Modifier le centre de facturation"
                : "Ajouter un centre de facturation"
            }
          >
            <CenterForm
              modalFormContent={null}
              newData={true}
              dataType={dataType}
              onClose={onClose}
              onSubmit={onSubmit}
            />
          </ModalUI>
        </>
      )}
    </>
  );
}

export const BillingCenterModal = ({
  isOpen,
  selectedData,
  onClose,
  onSubmit,
}) => {
  return (
    <ModalUI
      isOpen={isOpen}
      onClose={onClose}
      title={
        selectedData
          ? "Modifier le centre de facturation"
          : "Créer un centre de facturation"
      }
    >
      <CenterForm
        modalFormContent={selectedData}
        newData={true}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </ModalUI>
  );
};


