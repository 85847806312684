import React from "react";
import { useAppContext } from "../../context";
import { exnetSpaceColor } from "../../utils/Functions/otherFunctions";

export const EmballageDetails = ({ data }) => {
  const { isAuth, setIsAuth } = useAppContext();
  return (
    <div className="h-full p-4 bg-white rounded-md">
      <div className="flex justify-between card-title">
        <span>INFORMATIONS EMBALLAGE</span>
      </div>
      <hr />
      <div className={"md:grid grid-cols-3 gap-4 space-y-4 md:space-y-0"}>
        {/* emballage */}
        <div>
          <div className={"mt-3"}>
            <span className="uppercase titre-detail">Nom du Produit: </span>
            <span className="data-detail">
              {" "}
              {data ? data?.emballage?.nom_produit : ""}
            </span>
          </div>
          <div>
            <span className="uppercase titre-detail">Type de sonde : </span>
            <span className="data-detail">
              {" "}
              {data ? data?.type_sonde?.libelle : "-"}
            </span>
          </div>
          <div>
            <span className="uppercase titre-detail">Type de Diagobag :</span>
            <span className="data-detail">
              {" "}
              {data ? data?.type_diagnobag?.libelle : "-"}
            </span>
          </div>
        </div>

        <div>
          <h5 className="uppercase titre-detail">Dimensions extérieures</h5>
          <div>
            <span className="uppercase titre-detail">Longueur : </span>
            <span className="data-detail">
              {" "}
              {data ? data?.emballage?.dimensions_exterieures_longueur+" cm " : ""}
            </span>
          </div>

          <div>
           
            <span className="uppercase titre-detail">Largeur : </span>
            <span className="data-detail">
              {" "}
              {data ? data.emballage?.dimensions_exterieures_largeur+" cm " : ""}
            </span>
          </div>

          <div>
           
            <span className="uppercase titre-detail">Hauteur : </span>
            <span className="data-detail">
              {" "}
              {data ? data.emballage.dimensions_exterieures_hauteur+" cm " : ""}
            </span>
          </div>

          {/* volume */}

          <div>
            <span className="uppercase titre-detail">Volume utilisé : </span>
            <span className="data-detail">
              {" "}
              {data ? data.emballage.volume_utile+" l " : ""}
            </span>
          </div>
        </div>

        {/* dimension interieur */}
        <div>
          <h5 className="uppercase titre-detail">Dimensions intérieures</h5>
          <div>
            <span className="uppercase titre-detail">Longueur : </span>
            <span className="data-detail">
              {" "}
              {data ? data?.emballage?.dimensions_interieures_longueur+" cm " : ""}
            </span>
          </div>

          <div>
            <span className="uppercase titre-detail">Largeur : </span>
            <span className="data-detail">
              {" "}
              {data ? data.emballage?.dimensions_interieures_largeur+" cm " : ""}
            </span>
          </div>

          <div>
            <span className="uppercase titre-detail">Hauteur : </span>
            <span className="data-detail">
              {" "}
              {data ? data.emballage.dimensions_interieures_hauteur+" cm " : ""}
            </span>
          </div>

          {/* volume */}

          <div>
            <span className="uppercase titre-detail">Poids : </span>
            <span className="data-detail">
              {" "}
              {data ? data.emballage.poids+" kg " : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
