import HomeAdminPage from "../Pages/admin/HomeAdminPage.jsx";
import ExpeditionStatisticPage from "../Pages/admin/expeditionStatistiquePage.jsx";
import AuthPage from "../Pages/auth.page.jsx";
import ProfilePage from "../Pages/dashboard/profile.page.jsx";
import SettingPage from "../Pages/dashboard/setting.page.jsx";
import CarsView from "../components/Admin/Cars/Index.jsx";
import { EmployeesView, SoustraitantsView } from "../components/Admin/Employees/Index.jsx";
import { AdminView, DriversView, OpsView } from "../components/Admin/Actors/Index.jsx";
import FacturationCentersView from "../components/Centers/Index.jsx";
import ClientsView from "../components/Clients/Index.jsx";
import UsersView from "../components/Users/Index.jsx";
import AdminListComponent from "../components/adminListComponent/AdminListComponent.jsx";
import AdminContacts from "../components/contact/AdminContacts.jsx";
import GeneralStateComponent from "../components/generalStateComponent/GeneralStateComponent.jsx";
import HistoriesView from "../components/Admin/Histories/Index.jsx";
import { StatsCourses, StatsFreight } from "../components/Admin/Stats/Index.jsx";
import FuelsView from "../components/Admin/Fuels/Index.jsx";
import FacturationsCodeView from "../components/Admin/FacturationCodes/Index.jsx";
import { IndexTemplate } from "../components/Admin/Template/Index.jsx";
import PasswordForgotPage from "../Pages/passwordForgot.page.jsx";
import TransportTypes from "../components/Admin/TransportTypes/Index.jsx";
import { InfoForm } from "../components/form/InfoForm.jsx";
import IndexBanniere from "../components/Tableaux/Banniere/Index.jsx";
import { AdminEmballagePage } from "../Pages/admin/emballages/index.jsx";
import { AdminEmballageCapacityPage } from "../Pages/admin/capacity/index.jsx";
import { AdminSondePage } from "../Pages/admin/sonde/index.jsx";
import { AdminDiagnoBagPage } from "../Pages/admin/diagno-bag/index.jsx";


const ClientListAdminComponent = ({ dataType, title }) => {
  return (
    <AdminListComponent dataType={dataType} title={title}>
      <GeneralStateComponent dataType={dataType} />
    </AdminListComponent>
  );
};

export const adminRouter = [
  {
    tague: "dashboard",
    path: "",
    element: <HomeAdminPage />,
  },
  {
    tague: "auth",
    path: "auth",
    element: <AuthPage isAdmin={true} />,
  },
  {
    tague: "profile",
    path: "profile",
    element: <ProfilePage />,
  },
  {
    tague: "centre-facturation",
    path: "centre-facturation",
    element: <FacturationCentersView />,
  },
  {
    tague:'client',
    path: "clients",
    element: <ClientsView title="Liste des clients" type={"clients"} />,
  },
  {
    tague:'utilisateurs',
    path: "utilisateurs",
    element: (
      <UsersView dataType={"utilisateurs"} title={"Liste des utilisateurs"} />
    ),
  },
  {
    tague:'ops',
    path: "ops",
    element: <OpsView />,
  },
  {
    tague:'administrateurs',
    path: "administrateurs",
    element: <AdminView />,
  },
  {
    tague:'chauffeurs',
    path: "chauffeurs",
    element: (
      <DriversView
      />
    ),
  },
  {
    tague:'contacts',
    path: "contacts",
    element: <AdminContacts />,
  },
  {
    tague:'salaries',
    path: "salaries",
    element: <EmployeesView />,
  },
  {
    tague:'soustraitants',
    path: "soustraitants",
    element: (
      <SoustraitantsView />
    ),
  },
  {
    tague:'statiques/factures',
    path: "statiques/factures",
    element: (
      <AdminListComponent
        dataType={"statistique_factures"}
        title={"Liste des factures"}
      />
    ),
  },
  {
    tague:'facture/fuel',
    path: "facture/fuel",
    element: (
      <FuelsView />
    ),
  },
  {
    tague:'facture/code-facturation',
    path: "facture/code-facturation",
    element: (
      <FacturationsCodeView
      />
    ),
  },
  {
    tague:'statiques/freight',
    path: "statiques/freight",
    element: (
      <StatsFreight />
    ),
  },
  {
    tague:'statiques/expeditions',
    path: "statiques/expeditions",
    element: (
      <StatsCourses />
      // <AdminListComponent
      //     dataType={"statistique_expeditions"}
      //     title={"Liste des expéditions"}
      // />
    ),
  },
  {
    tague:'tracking-template',
    path: "tracking-template",
    element: (
      <IndexTemplate

      />
    ),
  },
  {
    tague:'fuel',
    path: "fuel",
    element: <AdminListComponent dataType={"fuel"} title={"Liste des fuel"} />,
  },
  {
    tague:'historiques',
    path: "historiques",
    element: (
      <HistoriesView
      />
    ),
  },
  {
    tague: "vehicules",
    path: "vehicules",
    element: <CarsView />,
  },
  {
    tague: "transport-types",
    path: "transport-types",
    element: <TransportTypes />,
  },
  {
    tague: "information",
    path: "information",
    element: <IndexBanniere />,
  },
  {
    tague: "types-emballages",
    path: "emballages",
    element: <AdminEmballagePage />,
  },
  {
    tague: "capacity-emballages",
    path: "capacity",
    element: <AdminEmballageCapacityPage />,
  },
  {
    tague: "emballages-daignobag",
    path: "daignobag",
    element: <AdminDiagnoBagPage />,
  },
  {
    tague: "emballages-sonde",
    path: "sonde",
    element: <AdminSondePage />,
  }

];

