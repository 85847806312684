import React, { useEffect, useState } from 'react'
import { EmballageDataStore } from '../../../states/tableData'
import { ErrorStore } from '../../../store/inputError'
import { InputComponent3 } from '../../../components/InputComponent/InputComponent3'
import { Selector } from '../../../components/Utils/Selectors/Selector'
import { ButtonComponent } from '../../../components/ButtonComponent'
import { EmballageTypeSelector } from '../../../components/emballage/emballageTypeSelector'
import { toast } from 'react-toastify'
import { adminPackageService } from '../../../services/admin/packageService'
import { emballageCreateValidator } from '../../../utils/validators'


export const FormCapacity = ({ setOpen, action = "add" }) => {

  const data = EmballageDataStore((state) => state.data)
  const modifyData = EmballageDataStore((state) => state.modifyData);
  const errorInformation = ErrorStore((state) => state.errorField);
  const setMessageError = ErrorStore((state) => state.setMessageError);
  const addData = EmballageDataStore((state) => state.addData);
  const setDataContent = EmballageDataStore((state) => state.setDataContent);
  const updateData = EmballageDataStore((state) => state.updateData);
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    try {

      const { error, valid, errorData } = emballageCreateValidator(data)

      if (!error) {
        setIsLoading(true)
        const toastsId = toast.loading("Création en cours ...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        adminPackageService.createPackage(data).then(
          (response) => {
            toast.update(toastsId, {
              render: "Enregistrement effectué avec succès",
              type: "success",
              isLoading: false,
              autoClose: 1000,
              position: "top-center",
            });
            addData({ ...response.data.data, active: 1 })
            setDataContent({})
            setIsLoading(false)
            setOpen(false)
          },
          (error) => {
            toast.update(toastsId, {
              render: "Erreur lors de l'enregistrement",
              type: "error",
              isLoading: false,
              autoClose: 1000,
              position: "top-center",
            });
            console.log(error)
            setIsLoading(false)
          })

      } else {

        toast.error(errorData.message, {
          position: 'top-center'
        })

        setMessageError(errorData)

      }
    } catch (error) {
      toast.error("Une erreur est survenue lors de l'enregistrement")
    }
  }

  const handleUpdateSubmit = () => {
    try {
const {type_emballage,updated_at,created_at,id,active,...other} = data
      const { error, valid, errorData } = emballageCreateValidator(other)

      if (!error) {
        setIsLoading(true)
        const toastsId = toast.loading("Modification en cours ...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        const packageId = action
        adminPackageService.updatePackage({ ...other, id: packageId }).then(
          (response) => {
            toast.update(toastsId, {
              render: "Modification effectuée avec succès",
              type: "success",
              isLoading: false,
              autoClose: 1000,
              position: "top-center",
            });
            updateData(packageId, other)
            setDataContent({})
            setIsLoading(false)
            setOpen(false)
          },
          (error) => {
            toast.update(toastsId, {
              render: "Erreur lors de la modification",
              type: "error",
              isLoading: false,
              autoClose: 1000,
              position: "top-center",
            });
            console.log(error)
            setIsLoading(false)
          })

      } else {
        toast.error(errorData.message, {
          position: 'top-center'
        })
        setMessageError(errorData)
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors de la modification")
    }
  }

  useEffect(() => {

    const fecthDetails = async () => {
      adminPackageService.getPackagesById({ id: action }).then(
        (response) => {
          console.log(response.data.data[0])
          setDataContent(response.data.data[0])
        },
        (error) => {
          console.log(error)
        }
      )
    }


    if (action !== "add") {
      fecthDetails()
    }

  }, [])

  return (

    <div className={"grid grid-cols-2 gap-2  space-y-3 md:space-y-0"}>
      {/* <Selector
        onSelect={(valeur) => {
          modifyData("mesure_type", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={"Unité de mesure".toUpperCase()}
        value={data.mesure_type}
        label="Unité de mesure *"
        withLabel={true}
        isError={
          errorInformation.field === "mesure_type"
        }
        data={[
          { value: "cm", label: "Centimètre" },
          { value: "cl", label: "Litre" },

        ]}
      /> */}

      <div className="col-span-2">
        <EmballageTypeSelector label={"Type d'emballage"} modifyData={(value) => modifyData("type_emballage_id", value)} errorInformation={errorInformation} value={data.type_emballage_id} />
      </div>


      <InputComponent3
        onChange={(valeur) => {
          modifyData("nom_produit", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={"Libellé".toUpperCase()}
        value={data.nom_produit}
        label="Libellé *"
        withLabel={true}
        isError={
          errorInformation.field === "nom_produit"
        }
      />

      <InputComponent3
        type='number'
        onChange={(valeur) => {
          modifyData("dimensions_exterieures_longueur", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={"Longueur extérieure".toUpperCase()}
        value={data.dimensions_exterieures_longueur}
        label="Longueur extérieure *"
        withLabel={true}
        isError={
          errorInformation.field === "dimensions_exterieures_longueur"
        }
      />

      <InputComponent3
        type='number'
        onChange={(valeur) => {
          modifyData("dimensions_exterieures_largeur", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={"Largeur extérieure".toUpperCase()}
        value={data.dimensions_exterieures_largeur}
        label="Largeur extérieure *"
        withLabel={true}
        isError={
          errorInformation.field === "dimensions_exterieures_largeur"
        }
      />

      <InputComponent3
        type='number'
        onChange={(valeur) => {
          modifyData("dimensions_exterieures_hauteur", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={" Hauteur extérieure".toUpperCase()}
        value={data.dimensions_exterieures_hauteur}
        label="Hauteur extérieure *"
        withLabel={true}
        isError={
          errorInformation.field === "dimensions_exterieures_hauteur"
        }
      />

      <InputComponent3
        type='number'
        onChange={(valeur) => {
          modifyData("dimensions_interieures_longueur", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={" Longueur intérieure".toUpperCase()}
        value={data.dimensions_interieures_longueur}
        label=" Longueur intérieure *"
        withLabel={true}
        isError={
          errorInformation.field === "dimensions_interieures_longueur"
        }
      />

      <InputComponent3
        type='number'
        onChange={(valeur) => {
          modifyData("dimensions_interieures_largeur", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={" Largeur intérieure".toUpperCase()}
        value={data.dimensions_interieures_largeur}
        label=" Largeur intérieure *"
        withLabel={true}
        isError={
          errorInformation.field === "dimensions_interieures_largeur"
        }
      />
      <InputComponent3
        type='number'
        onChange={(valeur) => {
          modifyData("dimensions_interieures_hauteur", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={" Hauteur intérieure".toUpperCase()}
        value={data.dimensions_interieures_hauteur}
        label=" Hauteur intérieure *"
        withLabel={true}
        isError={
          errorInformation.field === "dimensions_interieures_hauteur"
        }
      />

      <InputComponent3
        type='number'
        onChange={(valeur) => {
          modifyData("volume_utile", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={" Volume utile".toUpperCase()}
        value={data.volume_utile}
        label=" Volume utile *"
        withLabel={true}
        isError={
          errorInformation.field === "volume_utile"
        }
      />

      <InputComponent3
        type='number'
        onChange={(valeur) => {
          modifyData("poids", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={" Poids".toUpperCase()}
        value={data.poids}
        label=" Poids *"
        withLabel={true}
        isError={
          errorInformation.field === "poids"
        }
      />

      <InputComponent3
        type='number'

        onChange={(valeur) => {
          modifyData("autonomie", valeur);
        }}
        errorMessage={errorInformation.message}
        placeholder={" Autonomie".toUpperCase()}
        value={data.autonomie}
        label="Autonomie *"
        withLabel={true}
        isError={
          errorInformation.field === "autonomie"
        }
      />

      <div className="flex items-center justify-center col-span-2">
        <ButtonComponent
          onClick={action === "add" ? handleSubmit : handleUpdateSubmit}
          title={action === "add" ? "Enregistrer" : "Modifier"}
          disabled={isLoading}
        />
      </div>

    </div>
  )
}
