import { Fragment, useState, useEffect, useCallback } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  Bars3Icon,
  XMarkIcon,
  ClockIcon,
  CalendarIcon,
  UserIcon,
  CalculatorIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { SideBarMenu } from "./SideBarMenu";
import { clsx } from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import { getAllShippings } from "../../utils";
import AuthService from "../../services/auth/AuthService";
import Head from "../../components/HeadComponent/Head";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashLayout({
  accountNavigation,
  navigation,
  children,
  space = "admin",
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarTextShow, setSidebarTextShow] = useState(true);
  const { isAuth, setIsAuth } = useAppContext();
  const [currentTime, setCurrentTime] = useState(getCurrentTime);
  const [date, setDate] = useState(getCurrentDate);
  const [isActiveMenu, setisActiveMenu] = useState("dashboard");
  const [activeMenu, setActiveMenu] = useState(navigation[0]);
  const pathname = useLocation();
  const navigate = useNavigate();
  let checkAdmin = localStorage.getItem("admincheck");

  const allShippings = getAllShippings() === true;

  const logOut = () => {
    localStorage.removeItem("accessToken");
    // navigate(`/`)
    window.location.href = "/";
  };

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);

  const toggleAllShippings = useCallback(() => {
    localStorage.setItem("allShippings", allShippings ? false : true);
    //reload page
    window.location.reload();
  }, [allShippings]);

  const goToAdmin = () => {
    localStorage.setItem("accessToken", localStorage.getItem("oldToken"));
    AuthService.setHeader(localStorage.getItem("oldToken"));
    localStorage.removeItem("oldToken");
    localStorage.removeItem("admincheck");

    // navigate("/admin/dash/utilisateurs");
    window.location.href = "/admin/dash/utilisateurs";
  };

  const renderAllShippingBtn = (
    <button
      type="button"
      className={`h-auto w-auto mx-1 rounded-md p-2 text-sm font-medium ${
        allShippings ? "bg-red-500 " : "bg-gray-500 text-white"
      }`}
      onClick={toggleAllShippings}
    >
      <span className={"interface-icon"}>
        <i className={"fa fa-sitemap"}></i>
      </span>
      <span className={"interface-text"}>Toutes les expéditions</span>
    </button>
  );

  const removeEmballageTague = useCallback(() => {
    let newMenuList = [];
    if (!isAuth?.roles.includes("ROLE_OPS_EMBALLAGE") && !isAuth?.roles.includes("ROLE_ADMIN")) {
      newMenuList = navigation.filter((child) => child.tague !== "emballages");
    } else {
      newMenuList = [...navigation];
    }
    return newMenuList;
  }, [isAuth?.spaceName]);

  const profileLink =
    isAuth && isAuth?.userRole === "admin"
      ? "/admin/dash/profile"
      : `/${isAuth?.userRole}/${isAuth?.spaceName}/dash/profile`;

  return (
    <>
      <Head espace={isAuth?.spaceName}>
        <title>{`Exnet | ${isAuth?.userRole} ${isAuth?.spaceName}-Dashboard`}</title>
      </Head>
      <section className={"h-screen"}>
        <div className={"bg-[#E4E6EA] "}>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <SideBarMenu
                      menuData={removeEmballageTague()}
                      accountNavigation={accountNavigation}
                      sidebarTextShow={sidebarTextShow}
                      space={space}
                      setSidebarTextShow={setSidebarTextShow}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div
            className={clsx(
              { "lg:w-68": sidebarTextShow, "lg:w-20": !sidebarTextShow },
              "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex  lg:flex-col"
            )}
          >
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <SideBarMenu
              menuData={removeEmballageTague()}
              accountNavigation={accountNavigation}
              sidebarTextShow={sidebarTextShow}
              space={space}
              setSidebarTextShow={setSidebarTextShow}
            />
          </div>

          <div
            className={clsx({
              "lg:pl-72": sidebarTextShow,
              "lg:pl-20": !sidebarTextShow,
            })}
          >
            {/* {clsx({"lg:p-72":sidebarTextShow,"lg:p-26":!sidebarTextShow,})} */}
            {/*  "lg:pl-8 lg:pr-4": sidebarTextShow */}
            <div
              className={clsx(
                { "pl-2": !sidebarTextShow },
                "sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b sm:gap-x-6 "
              )}
            >
              <div
                className={
                  "border-gray-200 bg-white flex  flex-1 h-full py-3 rounded-lg"
                }
              >
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon
                    className={clsx(
                      {
                        "text-paris": space === "paris",
                        "text-medical": space === "medical",
                        "text-service": space === "service",
                        "text-admin": space === "admin",
                      },
                      "w-10 h-10"
                    )}
                    aria-hidden="true"
                  />
                </button>

                {/* Separator */}
                <div
                  className="w-px h-6 bg-gray-900/10 lg:hidden"
                  aria-hidden="true"
                />

                <div className="flex items-center self-stretch justify-between flex-1 gap-x-4 lg:gap-x-6">
                  <div className={"flex items-center gap-0 md:gap-4 md:pl-4"}>
                    <div>
                      <span className={"hidden md:block cursor-pointer"}>
                        <ArrowLeftIcon
                          className={clsx(
                            {
                              "text-paris": space === "paris",
                              "text-medical": space === "medical",
                              "text-service": space === "service",
                              "text-admin": space === "admin",
                            },
                            "w-6 h-6"
                          )}
                          aria-hidden="true"
                          onClick={() => navigate(-1)}
                        />
                      </span>
                    </div>

                    <div className="relative flex items-center text-sm">

                      <Timer space={space} />
                      {isAuth?.userRole === "client" &&
                        parseInt(isAuth?.calculatrice) === 1 && (
                          <Link
                            to={`/client/${isAuth?.spaceName}/dash/calculatrice`}
                            className={clsx(
                              {
                                "bg-paris": isAuth?.spaceName === "paris",
                                "bg-medical": isAuth?.spaceName === "medical",
                                "bg-service": isAuth?.spaceName === "service",
                              },
                              "mt-auto text-white rounded-md px-2 py-1 flex items-center gap-1 ml-2"
                            )}
                          >
                            {/* <i className={menuItem.icon}></i>{" "} */}
                            <CalculatorIcon className={clsx("w-5 h-5")} />

                            <span className="text-base cursor-pointer ">
                              {" "}
                              Calculatrice
                            </span>
                          </Link>
                        )}
                    </div>
                  </div>

                  <div className="flex items-center gap-x-4 lg:gap-x-6">
                    {/* debut des buttons pour les changements de menus */}
                    {(isAuth?.userRole === "ops" ||
                      isAuth?.userRole === "admin" ||
                      isAuth?.userRole === "client") && (
                      <div className={"overflow-auto w-full"}>
                        <ul className={"flex gap-3"}>
                          {isAuth?.userRole === "admin" ||
                          isAuth?.userRole === "ops" ? (
                            <li className="flex nav-item">
                              {(isAuth?.roles?.includes("ROLE_OPS_PARIS") ||
                                isAuth?.roles?.includes("ROLE_ADMIN")) && (
                                <button
                                  type="button"
                                  className={clsx(
                                    {
                                      active:
                                        isAuth?.spaceName?.includes === "paris",
                                    },
                                    "bg-paris text-sm font-medium rounded-lg p-2 text-white mx-1"
                                  )}
                                  onClick={() => {
                                    window.location.href = "/ops/paris/dash";
                                    // navigate("/ops/paris/dash")
                                  }}
                                >
                                  <span className={"interface-icon"}>
                                    <i className={"fa fa-university"}></i>
                                  </span>
                                  <span className={"interface-text"}>
                                    Paris
                                  </span>
                                </button>
                              )}

                              {(isAuth?.roles?.includes("ROLE_OPS_MEDICAL") ||
                                isAuth?.roles?.includes("ROLE_ADMIN")) && (
                                <button
                                  type="button"
                                  className={clsx(
                                    {
                                      active:
                                        isAuth?.spaceName?.includes ===
                                        "medical",
                                    },
                                    "bg-medical text-sm font-medium rounded-lg p-2 text-white mx-1"
                                  )}
                                  onClick={() => {
                                    window.location.href = "/ops/medical/dash";
                                    // navigate("/ops/medical/dash");
                                  }}
                                >
                                  <span className={"interface-icon"}>
                                    <i className={"fa fa-stethoscope"}></i>
                                  </span>
                                  <span className={"interface-text"}>
                                    Medicals
                                  </span>
                                </button>
                              )}

                              {(isAuth?.roles?.includes("ROLE_OPS_SERVICE") ||
                                isAuth?.roles?.includes("ROLE_ADMIN")) && (
                                <button
                                  type="button"
                                  className={clsx(
                                    {
                                      active:
                                        isAuth?.spaceName?.includes ===
                                        "service",
                                    },
                                    "bg-service text-sm font-medium rounded-lg p-2 text-white mx-1"
                                  )}
                                  onClick={() => {
                                    window.location.href = "/ops/service/dash";
                                    // navigate("/ops/service/dash");
                                  }}
                                >
                                  <span className={"interface-icon"}>
                                    <i className={"fa fa-fire"}></i>
                                  </span>
                                  <span className={"interface-text"}>
                                    Services
                                  </span>
                                </button>
                              )}
                              {isAuth?.roles?.includes("ROLE_ADMIN") && (
                                <button
                                  type="button"
                                  className={clsx(
                                    {
                                      active:
                                        isAuth?.spaceName?.includes === "admin",
                                    },
                                    "bg-admin text-sm font-medium rounded-lg p-2 text-white mx-1"
                                  )}
                                  onClick={() => {
                                    window.location.href = "/admin/dash";
                                    // navigate("/admin/dash");
                                  }}
                                >
                                  <span className={"interface-icon"}>
                                    <i className={"fa fa-cog"}></i>
                                  </span>
                                  <span className={"interface-text"}>
                                    Admin
                                  </span>
                                </button>
                              )}

                              {/* All render button */}
                              {isAuth?.userRole !== "admin" && (
                                <>{renderAllShippingBtn}</>
                              )}
                            </li>
                          ) : isAuth?.userRole !== "exploitation" ? (
                            <>{/* {renderAllShippingBtn} */}</>
                          ) : null}

                          {checkAdmin && (
                            <li className="nav-item">
                              <button
                                type="button"
                                className={clsx(
                                  {
                                    "bg-paris": isAuth?.spaceName === "paris",
                                    "bg-medical":
                                      isAuth?.spaceName === "medical",
                                    "bg-service":
                                      isAuth?.spaceName === "service",
                                  },
                                  "px-3 py-2 mx-8 text-white rounded-lg"
                                )}
                                onClick={() => {
                                  goToAdmin();
                                }}
                              >
                                <span className={"interface-icon"}>
                                  <i className={"fa fa-sitemap"}></i>
                                </span>
                                <span className={"interface-text"}>
                                  Retour à l'admin
                                </span>
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                    {/* fin */}

                    {/* Separator */}
                    <div
                      className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                      aria-hidden="true"
                    />

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ">
                      <Menu.Button className="-m-1.5 flex items-center p-1.5 xs:mr-3 md:mr-0 min-w-8">
                        <span className="sr-only">Open user menu</span>

                        <UserIcon
                          className={clsx(
                            {
                              "text-paris": isAuth?.spaceName === "paris",
                              "text-service": isAuth?.spaceName === "service",
                              "text-medical": isAuth?.spaceName === "medical",
                              "text-admin": isAuth?.spaceName === "admin",
                            },
                            "h-8 w-8  font-bold rounded-full shadow-md"
                          )}
                        />
                        <span className="items-center hidden md:flex">
                          <span
                            className="ml-4 text-sm font-normal leading-6 text-gray-900"
                            aria-hidden="true"
                          >
                            {isAuth?.name && <>{isAuth?.name}.</>}

                            <small className={"block text-xs "}>
                              {" "}
                              {isAuth?.userRole?.toUpperCase()}{" "}
                            </small>
                          </span>
                          <ChevronDownIcon
                            className={clsx(
                              {
                                "text-paris": space === "paris",
                                "text-medical": space === "medical",
                                "text-service": space === "service",
                                "text-admin": space === "admin",
                              },
                              "w-8 h-8 mx-1"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item key={"item.name"}>
                            <Link
                              to={profileLink}
                              className={
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              }
                            >
                              Votre profil
                            </Link>
                          </Menu.Item>

                          <Menu.Item key={"item.name"}>
                            <span
                              onClick={logOut}
                              className={
                                "block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer "
                              }
                            >
                              Déconnexion
                            </span>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <main className="py-5 bg-[#E4E6EA] min-h-screen">
              <div className="px-2">
                <section className={clsx({ "px-2": !sidebarTextShow })}>
                  {children}
                </section>
              </div>
            </main>
          </div>
        </div>
      </section>
    </>
  );
}

const getCurrentTime = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  let seconds = date.getSeconds();
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? seconds : seconds}`;
};

const getCurrentDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day < 10 ? "0" + day : day}/${
    month < 10 ? "0" + month : month
  }/${year}`;
};

const Timer = ({ space }) => {
  const [currentTime, setCurrentTime] = useState(getCurrentTime);
  const [date, setDate] = useState(getCurrentDate);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="items-center flex-1 hidden gap-2 md:flex ">
      <div className={"flex items-center gap-1"}>
        <CalendarIcon
          className={clsx(
            {
              "text-paris": space === "paris",
              "text-medical": space === "medical",
              "text-service": space === "service",
              "text-admin": space === "admin",
            },
            "w-6 h-6"
          )}
        />
        <span className={""}>{date}</span>
      </div>
      <div className={"flex items-center gap-1"}>
        <ClockIcon
          className={clsx(
            {
              "text-paris": space === "paris",
              "text-medical": space === "medical",
              "text-service": space === "service",
              "text-admin": space === "admin",
            },
            "w-6 h-6"
          )}
        />
        <span className={""}>{currentTime}</span>
      </div>
    </div>
  );
};
